import React, { Component, useState } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import { StandardContainer } from '../../CommonStyles';
import Stepper from './Stepper';
import PreferencesPage from './PreferencesPage';
import RefiningPage from './RefiningPage';
import RecommendationsPage from './RecommendationsPage';
import CritiquingPage from './CritiquingPage';
import { fetchCities, fetchAllCities, fetchRecommendations, fetchRefinedRecommendations, postSurvey, fetchRecommendationWithCritiques, fetchRecommendationWithEliminationCritiques, fetchInitialRecommendationWithCritiques } from './api';
import queryString from 'query-string';
import { Notification } from 'react-notification';
import FinalRecommendationPage from "./FinalRecommendationPage";

import { flow, sortBy, map } from 'lodash/fp';
import { attributes, attributeOrder, overallUtilityBySliderStates, multiPartition, utilityAtLeast, getItemGroups } from './attributes';


// --------------------- scaffolding --------------------------

const scaffoldingConfig = version => [
  [<div>We will now introduce you to the recommender system that you will work with over the course of this study. Please follow this introduction carefully.</div>],
  [<div>This system will recommend cities around the world as potential travel destinations. On the right, you see your list of recommendations. As the system does not know your preferences yet, for now, all cities appear as matching your preferences perfectly.</div>],
  [<>
  <div>The system uses three colors to indicate how well each city matches your preferences:</div>
  {[0,1,2].map(index =>
  <span style={{display: "inline-block", marginLeft: "20px", padding: "5px 10px", borderRadius: "4px", color: "#6B7476"}}>
    <span style={{display: "inline-block", height: "20px", width: "20px", borderRadius: "20px", backgroundColor: `rgba(0, 100, 0, ${[1.0,0.4,0.2][index]})`, verticalAlign: "top"}} />
    <span style={{display: "inline-block", width: "170px", textAlign: "left", verticalAlign: "top", paddingLeft: "12px"}}>{["perfectly matching", "well matching", "poorly matching"][index]}</span>
  </span>)}
  <div>For each city, you can see a percentage value that indicates how well that city matches your preferences. The list is sorted by this value, highest match values appear at the top.</div>
  </>],
  [<><div>To adjust your preferences, you use sliders, like the one below for the mean temperature.</div><div style={{fontSize: "70%", marginTop: "7px"}}>Try using the slider now: select different ranges and observe how the slider and your list of recommendations change. You can drag the slider endpoints or move the whole slider at once.</div></>],
  [<><div>When you click any recommendation in the list, the selected city is displayed on the slider.</div><div style={{fontSize: "70%", marginTop: "7px"}}>Try clicking a few cities and observe how they are displayed on the slider. Click the selected city again to remove the selection.</div></>],
  ].concat(

    !(true) ? [] : [
      [<><div>Small blue numbers on the top of the slider show how many cities are within the range, as well as how many cities lie outside of it - to the left (lower) and to the right (higher).</div><div style={{fontSize: "70%", marginTop: "7px"}}>Try adjusting the range and observe how the numbers change.</div></>],
    ],

    !(version === "9f2c3dc5" || version === "4cc20c83") ? [] : [
      [<><div>The slider also shows how the available cities are distributed. In areas with a lot of "mountains", a large number of cities exist. In "flat" areas, not many cities can be found.</div><div style={{fontSize: "70%", marginTop: "7px"}}>Try selecting a range with many and then a range with few cities.</div></>],
    ],

    !(version === "4cc20c83") ? [] : [
      [<><div>Finally, the slider uses the same three colors from the list of recommendations to indicate which cities match your preferences perfectly, well, or badly.</div><div style={{fontSize: "70%", marginTop: "7px"}}>Observe how the colored areas always correspond to the recommendations as you adjust the slider.</div></>],
    ],

    !(true) ? [] : [
      [<><div>To end this introduction, you can now freely explore a setting with two sliders. Note that recommendations are only perfect matches if they lie within all selected ranges.</div><div style={{fontSize: "70%", marginTop: "7px"}}>Once you feel confident that you understand the system, click 'Start Task' below.</div></>],
    ],
  );




// ------------------------ mini tasks ------------------------

const miniTasks = {
  // two slider tasks
  "fe2d3a": {
      name: "2-correlated-bad",
      id: "fe2d3a",
      chosenAttributeOrder: ["temperature", "precipitation"],
      sliderStates: { temperature: [0,1], precipitation: [0,1] },
      taskDescription: "Your friend is looking for an adventure! They want to go somewhere both cold and rainy!",
      promptQuestionPairs: [
        [
          "Slowly decrease the maximum Temperature to about 12 °C and observe what happens.",
          "Imagine you adjust the Precipitation slider now and increase its minimum far to the right.",
          [], ["temperature"],
          [["temperature", 0, 0.40]],
        ],
        [
          "Slowly increase the minimum Precipitation to about 200 mm and observe what happens.",
          "What has actually happened?",
          ["precipitation"], [],
          [["precipitation", 0.70, 1]],
        ],
      ],
  },
  "abc312": {
      name: "2-independent-good",
      id: "abc312",
      chosenAttributeOrder: ["temperature", "cost"],
      sliderStates: { temperature: [0,1], cost: [0,1] },
      taskDescription: "Your friend is on an adventurous spending spree. They would like to go somewhere both cold and expensive.",
      promptQuestionPairs: [
        [
          "Slowly decrease the maximum Temperature to about 12 °C and observe what happens.",
          "Imagine you adjust the Cost of Living slider now and increase its minimum far to the right.",
          [], ["temperature"],
          [["temperature", 0, 0.40]],
        ],
        [
          "Slowly increase the minimum Cost of Living to about 20 € and observe what happens.",
          "What has actually happened?",
          ["cost"], [],
          [["cost", 0.70, 1]],
        ],
      ],
  },
  // three slider tasks
  "fff358": {
      name: "3-bad",
      id: "fff358",
      chosenAttributeOrder: ["venueCount", "cost", "precipitation"],
      sliderStates: { venueCount: [0.12,0.42], cost: [0,1], precipitation: [0,1] }, //261K...1038K
      taskDescription: "Below, you have already entered your friend's preferences regarding the city's Size. But your friend also wants to go somewhere both expensive and rainy!",
      promptQuestionPairs: [
        [
          "Slowly increase the minimum Cost of Living to about 15 € and observe what happens.",
          "Imagine you adjust the Precipitation slider now and increase its minimum far to the right.",
          ["cost"], [], 
          [["cost", 0.45, 1]],
        ],
        [
          "Slowly increase the minimum Precipitation to about 200 mm and observe what happens.",
          "What has actually happened?",
          ["precipitation"], [], 
          [["precipitation", 0.70, 1]],
        ],
      ],
  },
  "eee247": {
      name: "3-okayish",
      id: "eee247",
      chosenAttributeOrder: ["venueCount", "cost", "precipitation"],
      sliderStates: { venueCount: [0.06,0.54], cost: [0,1], precipitation: [0,1] }, //198K...1803K
      taskDescription: "Below, you have already entered your friend's preferences regarding the city's Size. But your friend also wants to go someplace that's not too cheap and also really rainy!",
      promptQuestionPairs: [
        [
          "Slowly increase the minimum Cost of Living to about 10 € and observe what happens.", // was: 9.60 €
          "Imagine you adjust the Precipitation slider now and increase its minimum far to the right.",
          ["cost"], [], 
          [["cost", 0.22, 1]],
        ],
        [
          "Slowly increse the minimum Precipitation to about 200 mm and observe what happens.",
          "What has actually happened?",
          ["precipitation"], [], 
          [["precipitation", 0.70, 1]],
        ],
      ],
 },
  "ddd136": {
      name: "3-good",
      id: "ddd136",
      chosenAttributeOrder: ["venueCount", "cost", "precipitation"],
      sliderStates: { venueCount: [0.00,0.40], cost: [0,1], precipitation: [0,1] }, //150K...1500K
      taskDescription: "Below, you have already entered your friend's preferences regarding the city's Size. But your friend also wants to go somewhere both cheap and rainy!",
      promptQuestionPairs: [
        [
          "Slowly decrease the maximum Cost of Living to about 12 € and observe what happens.",
          "Imagine you adjust the Precipitation slider now and increase its minimum far to the right.",
          [], ["cost"],
          [["cost", 0, 0.40]],
        ],
        [
          "Slowly increase the minimum Precipitation to about 200 mm and observe what happens.",
          "What has actually happened?",
          ["precipitation"], [], 
          [["precipitation", 0.70, 1]]
        ],
      ],
  },
};

const threeSliderPermutations = [
  ["ddd136","eee247","fff358"],   
  ["ddd136","fff358","eee247"],   
  ["eee247","ddd136","fff358"],   
  ["eee247","fff358","ddd136"],   
  ["fff358","ddd136","eee247"],   
  ["fff358","eee247","ddd136"],   
];
const twoSliderPermutations = [
  ["abc312", "fe2d3a", "dummie"],
  ["fe2d3a", "abc312", "dummie"],
];
const possibleThreeTaskCombinations = [
  ["ddd136", "fe2d3a", "eee247"], // good, bad, okayish
  ["eee247", "fe2d3a", "ddd136"], // okayish, bad, good
  ["fff358", "abc312", "eee247"], // bad, good, okayish
  ["eee247", "abc312", "fff358"], // okayish, good, bad
]



/*
// ------------------------ attributes ------------------------

// for UI stuff we use shortened attribute names ("attrKeys") compared to the city object or user model properties (which we call "features")
//
// keys: shortened attribute names ("attrKeys")
// values[0]: city object or user model properties (which we call "features")
const attributes = {
  "cost": ["costOfLivingIndex", "Cost of Living",      val => ((val+0.25)*20).toFixed(2)+" €"], // was 5€ in Kairo, sind 10€ in Prag und 21€ in NYC
  "venueCount": ["venueCount", "Size",                 val => [(Math.pow(10, val*2+2)*1.5).toFixed(0)].map(str => str.length > 3 ? str.slice(0,-3)+","+str.slice(-3) : str)[0]+" K"],
  "temperature": ["averageTemperature", "Temperature", val => (26.6*val+3.4).toFixed(2)+" °C"],
  "precipitation": ["averagePrecipitation", "Precipitation",    val => (266.6*val+1.6).toFixed(1)+" mm"], // per Month
  "food": ["food", "Dining",                           val => (val*4+1).toFixed(2)+" stars"],
  "nightlife": ["nightlife", "Party",                  val => (val*4+1).toFixed(2)+" stars"],
  "arts": ["artsAndEntertainment", "Arts",             val => (val*4+1).toFixed(2)+" stars"],
  "outdoor": ["outdoorsAndRecreation", "Outdoor",      val => (val*4+1).toFixed(2)+" stars"],
  /////"travel": ["travelAndTransport", "Travel & Transport"],
  /////"shops": ["shopsAndServices", "Shops & Services"],
}
const attributeOrder = ['cost', 'venueCount', 'temperature', 'precipitation', 'food', 'nightlife', 'arts', 'outdoor'
//, 'travel', 'shops'
];
*/

const taskDescriptions = {
  a: "Find a city that you personally would really love to travel to.",
  b: "Your friend is looking for the best city to travel to. You would like to help them find the best match. They prefer really large cities (3 million inhabitants or more would be nice) and cities with great nightlife and party options (at least 3.00 stars rating if possible). Find a city to suggest to them.",
};

const initialState = {
  taskDescription: "",
  confirmed: false,

  cities: [],
  allCities: [],
  recommendations: [],
  selectedCities: new Set(),
  refinements: {
    cost: 0,
    temperature: 0,
    food: 0,
    arts: 0,
    outdoors: 0,
    nightlife: 0,
    shops: 0,
    transport: 0
  },
  isLoading: false,
  shouldShowSelectionWarning: false,
  refinementsCompleted: false,
  surveysSubmitted: [false, false, false], // initial, postTask, postTest
  miniTaskSurveysSubmitted: [false, false, false, false, false, false], // 1a, 1b, 2a, 2b, 3a, 3b
  startTime: 0,
  endTime: 0,
  startClicks: 0,
  endClicks: 0,
  scaffoldingStats: {},
  clickCount: 0,
  dismissedCities: [],
  cityRefreshCount: 0,
  critiquedFeature: '',

  highlightedCityId: undefined,
  miniTaskSubstep: 0,
  scaffoldingSubstep: 0,

  chosenAttributeOrder: [],
  sliderStates: {},
  hiddenAttrKeys: [],
}

const CITY_SELECTION_LOWER_BOUND = 3;
const CITY_SELECTION_UPPER_BOUND = 5;

class Recommender extends Component {

  constructor(props) {
    super(props);
    this.clickCountRef = React.createRef(0);

    const { location } = props;
    const urlParameters = queryString.parse(location.search);
    const taskOrderArray = ["ad6283"];//, "28bf47"]; // taskOrderIDs ["ab", "ba"]

    let initialTaskOrder = "";
    if (urlParameters.t) {
      const taskOrder = urlParameters.t;
      if (taskOrderArray.includes(taskOrder)) {
        console.log("returned taskOrder "+taskOrder);
        initialTaskOrder = taskOrder;
      }
    }
    if (initialTaskOrder === "") {
    console.log("generated taskOrder")
    const taskOrder = taskOrderArray[Math.floor(Math.random() * taskOrderArray.length)];
    initialTaskOrder = taskOrder
    }

    const taskIndices = initialTaskOrder === "ad6283" ? ["a","b"] : ["b","a"];

    /*
    const threeSP = Math.floor(Math.random()*threeSliderPermutations.length);
    const twoSP   = Math.floor(Math.random()*twoSliderPermutations  .length);
    console.log(threeSP);
    console.log(twoSP);
    const a1 = threeSliderPermutations[threeSP];
    const a2 = twoSliderPermutations  [twoSP  ];
    const miniTaskOrder = ( [].concat(...Array.from({ length: 3 }, (_, i) => [a1[i], a2[i]]), a1, a2) ).slice(0, 5);
    */
    const miniTaskOrder = possibleThreeTaskCombinations[ Math.floor(Math.random()*possibleThreeTaskCombinations.length) ];

    this.state = {...initialState, taskDescription: taskDescriptions[taskIndices[0]], miniTaskOrder, miniTasksCompleted: 0 };
  }

  componentWillMount() {
    document.addEventListener('click', this.incrementClickCount, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.incrementClickCount, false);
  }

  fixVenueCount(allCities) {
    allCities.forEach((city, index) => {
      const venueCount = allCities[index][ attributes["venueCount"][0]+"Scaled" ];
      const newVenueCount = Math.log10(Math.min(venueCount + 0.01, 1.00)*100)/2;
      allCities[index][ attributes["venueCount"][0]+"Scaled" ] = newVenueCount;
    });
    return allCities;
  }

  componentDidMount() {
    fetchAllCities().then(allCities => this.setState({ allCities: this.fixVenueCount(allCities) }));
  }

  recordCityRefresh = () => {
    const { cities, cityRefreshCount, dismissedCities } = this.state;
    const citynames = cities.map(c => c.name);

    this.setState({
      cityRefreshCount: cityRefreshCount + 1,
      dismissedCities: [...dismissedCities, citynames]
    });
  }

  incrementClickCount = () => {
    const { pathname } = this.props.location;
    if (true
      /*pathname === '/rec/preferences' || 
      pathname === '/rec/critiquing-9f2c3dc5' || 
      pathname === '/rec/critiquing-4cc20c83' || 
      pathname === '/rec/critiquing-64f91682'*/
      ) {
      this.setState({ clickCount: this.state.clickCount + 1 })
      this.clickCountRef.current++;
    }
  }

  resetRecommenderProgress = () => {
    const { match, history, location } = this.props;
    this.setState(initialState);

    history.push(`${match.path}/preferences${location.search}`);
  }

  startTimeTracking = () => {
    this.setState({ startTime: (new Date()).getTime() });
  }
  endTimeTracking = () => {
    this.setState({ endTime: (new Date()).getTime() });
  }
  startClickTracking = (clickCountRef) => {
    this.setState({ startClicks: clickCountRef.current });
  }
  endClickTracking = (clickCountRef) => {
    this.setState({ endClicks: clickCountRef.current });
  }

  shouldResetRecommenderProgress = () => {
    const { cities } = this.state;
    return false; //cities.length === 0;
  }

  getTaskOrder = () => {
    const { location } = this.props;
    const urlParameters = queryString.parse(location.search);
    const taskOrderArray = ["ad6283"];//, "28bf47"]; // taskOrderIDs ["ab", "ba"]

    if (urlParameters.t) {
      const taskOrder = urlParameters.t;
      if (taskOrderArray.includes(taskOrder)) {
        console.log("returned taskOrder "+taskOrder);
        return taskOrder;
      }
    }

    console.log("generated taskOrder")
    return taskOrderArray[Math.floor(Math.random() * taskOrderArray.length)];
  }

  getVersion = () => {
    const { location } = this.props;
    const urlParameters = queryString.parse(location.search);
    const versionArray = ["9f2c3dc5", "4cc20c83", "64f91682"]; // versionIDs [2, 3, 4]

    if (urlParameters.v) {
      const version = urlParameters.v;
      if (versionArray.includes(version)) {
        return version;
      }
    }

    return versionArray[Math.floor(Math.random() * versionArray.length)];
  }

  getVersionID = () => {
    const { location } = this.props;
    const urlParameters = queryString.parse(location.search);
    if (urlParameters.v) {
      const version = urlParameters.v;
      switch (version) {
        case '9f2c3dc5': // versionID === 2 => Baseline critiquing
          return 2;
        case '4cc20c83': // versionID === 3 => Navigation by Revealing Tradeoffs (?)
          return 3;
        case '64f91682': // versionID === 4 => Navigation by Revealing Tradeoffs, with Elimination
          return 4;
        default:
          return [2, 3, 4][Math.floor(Math.random() * 3)];
      }
    }
    return [2, 3, 4][Math.floor(Math.random() * 3)];
  }

  getVersionName = () => {
    const { location } = this.props;
    const urlParameters = queryString.parse(location.search);

    if (urlParameters.v) {
      const version = urlParameters.v;
      switch (version) {
        case '9f2c3dc5': // versionID === 2 => Baseline critiquing
          return "unit";
        case '4cc20c83': // versionID === 3 => Navigation by Revealing Tradeoffs (?)
          return "conversational";
        case '64f91682': // versionID === 4 => Navigation by Revealing Tradeoffs, with Elimination
          return 'conversational-eliminating';
        default:
          return 'Unknown';
      }
    }
    return "Unknown";
  }

  getCode = () => {
    const { location } = this.props;
    const urlParameters = queryString.parse(location.search);

    return urlParameters.c;
  }

  submitSurvey = (whichQuestionnaire, surveyData) => {
    const { /*cities, selectedCities, refinements, recommendations,*/ startTime, endTime, startClicks, endClicks, scaffoldingStats, clickCount/*, dismissedCities, cityRefreshCount*/ } = this.state;
    const version = this.getVersion();
    const code = this.getCode();

    //const selectedCityNames = filter(cities, c => selectedCities.has(c.id)).map(c => c.name);

    const maybeHighlightedCity = this.state.allCities.filter(c => c.id === this.state.highlightedCityId);
    const highlightedCityNameAndUtility = maybeHighlightedCity.length === 0 ? [] : [maybeHighlightedCity[0].name, overallUtilityBySliderStates(maybeHighlightedCity[0], this.state.sliderStates, this.state.chosenAttributeOrder.filter(otherAttrKey => !this.state.hiddenAttrKeys.includes(otherAttrKey)))];

    const surveyCompleteData = {
      //initialCities: cities.map(c => c.name),
      //recommendations: recommendations.map(c => c.name),
      //selectedCities: selectedCityNames,
      //refinements,
      version,
      code,
      whichQuestionnaire,
      surveyData,
      extraData: whichQuestionnaire !== "afterFreeTask" ? {} : {
        chosenAttributeOrder: this.state.chosenAttributeOrder,
        sliderStates: this.state.sliderStates,
        hiddenAttrKeys: this.state.hiddenAttrKeys,
        itemGroupLengths: getItemGroups(this.state.allCities, this.state.sliderStates, this.state.chosenAttributeOrder, this.state.hiddenAttrKeys).map(itemGroup => itemGroup.length),
        selectedCity: highlightedCityNameAndUtility,
      },
      ...scaffoldingStats,
      //clickCount, //TEMP
      //clickCountRef: this.clickCountRef.current,
      //dismissedCities,
      //cityRefreshCount,
      recommendationTimeTaken: whichQuestionnaire !== "afterFreeTask" ? 0 : (endTime - startTime),
      recommendationClicksTaken: whichQuestionnaire !== "afterFreeTask" ? 0 : (endClicks - startClicks),

      surveyTimeTaken: (new Date()).getTime() - endTime,
      surveyClicksTaken: this.clickCountRef.current - endClicks,
    };

    postSurvey(surveyCompleteData);
    const newSurveysSubmitted = [...this.state.surveysSubmitted];
    const whichIndex = whichQuestionnaire === "initial" ? 0 : whichQuestionnaire === "afterFreeTask" ? 1 : 2
    newSurveysSubmitted[whichIndex] = true;
    this.setState({ surveysSubmitted: newSurveysSubmitted })
  }

  submitMiniTaskSurvey = (miniTaskIndex, surveyIndex, surveyData) => {
    const { /*cities, selectedCities, refinements, recommendations,*/ startTime, endTime, startClicks, endClicks, clickCount/*, dismissedCities, cityRefreshCount*/ } = this.state;
    const version = this.getVersion();
    const code = this.getCode();

    //const selectedCityNames = filter(cities, c => selectedCities.has(c.id)).map(c => c.name);

    const maybeHighlightedCity = this.state.allCities.filter(c => c.id === this.state.highlightedCityId);
    const highlightedCityNameAndUtility = maybeHighlightedCity.length === 0 ? [] : [maybeHighlightedCity[0].name, overallUtilityBySliderStates(maybeHighlightedCity[0], this.state.sliderStates, this.state.chosenAttributeOrder.filter(otherAttrKey => !this.state.hiddenAttrKeys.includes(otherAttrKey)))];

    const miniTaskId =  this.state.miniTaskOrder[  miniTaskIndex  ];

    const surveyCompleteData = {
      //initialCities: cities.map(c => c.name),
      //recommendations: recommendations.map(c => c.name),
      //selectedCities: selectedCityNames,
      //refinements,
      version,
      code,
      miniTaskIndex,
      miniTaskId,
      surveyIndex,
      surveyData,
      extraData: {
        chosenAttributeOrder: this.state.chosenAttributeOrder,
        sliderStates: this.state.sliderStates,
        hiddenAttrKeys: this.state.hiddenAttrKeys,
        itemGroupLengths: getItemGroups(this.state.allCities, this.state.sliderStates, this.state.chosenAttributeOrder, this.state.hiddenAttrKeys).map(itemGroup => itemGroup.length),
        selectedCity: highlightedCityNameAndUtility,
      },
      //clickCount, //TEMP
      //clickCountRef: this.clickCountRef.current,
      //dismissedCities,
      //cityRefreshCount,
      recommendationTimeTaken: endTime - startTime,
      recommendationClicksTaken: endClicks - startClicks,

      surveyTimeTaken: (new Date()).getTime() - endTime,
      surveyClicksTaken: this.clickCountRef.current - endClicks,
    };

    postSurvey(surveyCompleteData);
    const newMiniTaskSurveysSubmitted = [...this.state.miniTaskSurveysSubmitted];
    newMiniTaskSurveysSubmitted[miniTaskIndex*2 + surveyIndex] = true;
    this.setState({ miniTaskSurveysSubmitted: newMiniTaskSurveysSubmitted })
  }
/*
  submitCritiquingSurvey = surveyData => {
    const { cities, selectedCities, refinements, startTime, endTime, clickCount, dismissedCities, cityRefreshCount } = this.state;
    const version = this.getVersionID();
    const code = this.getCode();

    const selectedCityNames = filter(cities, c => selectedCities.has(c.id)).map(c => c.name);

    postSurvey({
      initialCities: cities.map(c => c.name),
      // recommendations: recommendations.map(c => c.name),
      selectedCities: selectedCityNames,
      refinements,
      ...surveyData,
      version,
      code,
      clickCount,
      dismissedCities,
      cityRefreshCount,
      recommendationTimeTaken: endTime - startTime,

      surveyTimeTaken: (new Date()).getTime() - endTime
    });

    this.setState({ surveySubmitted: true })
  }
*/

  resetSelectedCities = () => {
    this.setState({ selectedCities: new Set() });
  }

  getCities = async () => {
    this.setState({ isLoading: true });
    const cities = await fetchCities();
    this.setState({ cities, isLoading: false });
  }

  getRecommendations = async () => {
    const { selectedCities } = this.state;
    this.setState({ isLoading: true });
    const recommendations = await fetchRecommendations(selectedCities);
    this.setState({ recommendations, isLoading: false });
  }

  refineRecommendations = async () => {
    const { refinements } = this.state;
    this.setState({ isLoading: true });
    const recommendations = await fetchRefinedRecommendations(refinements);
    this.setState({
      recommendations, isLoading: false, refinements: {
        cost: 0,
        temperature: 0,
        food: 0,
        arts: 0,
        outdoors: 0,
        nightlife: 0,
        shops: 0,
        transport: 0
      }
    });
  }

  getInitialRecommendationWithCritiques = async () => {
    const { selectedCities } = this.state;
    this.setState({ isLoading: true });
    const version = this.getVersionID();
    const recommendationData = await fetchInitialRecommendationWithCritiques(selectedCities, version);
    const recommendations = recommendationData.cities;
    const statistics = recommendationData.statistics;
    this.setState({ recommendations, statistics, isLoading: false });
  }

  getRecommendationWithCritiques = async (selectedCityId, feature) => {
    this.setState({ isLoading: true });
    const version = this.getVersionID();
    const critiquedFeature = feature;
    const recommendationData = await fetchRecommendationWithCritiques(selectedCityId, critiquedFeature, version);
    const recommendations = recommendationData.cities;
    const statistics = recommendationData.statistics;
    this.setState({ recommendations, statistics, isLoading: false });
  }

  getRecommendationWithEliminationCritiques = async (selectedCityId, feature) => {
    this.setState({ isLoading: true });
    const version = this.getVersionID();
    const critiquedFeature = feature;
    const recommendationData = await fetchRecommendationWithEliminationCritiques(selectedCityId, critiquedFeature, version);
    const recommendations = recommendationData.cities;
    const statistics = recommendationData.statistics;
    this.setState({ recommendations, statistics, isLoading: false });
  }

  goToNextStep = path => {
    this.props.history.push(path);
  }

  getCurrentStepIndex = (version, taskOrder) => {
    const { location } = this.props;
    const steps = this.getSteps(version, taskOrder);

    for (const [i, step] of steps.entries()) {
      if (location.pathname.endsWith(step.pathname)) {
        return i;
      }
    }

    return 0;
  }

  nextStepEnabled = (version, taskOrder) => {
    const steps = this.getSteps(version, taskOrder);
    const currentStep = this.getCurrentStepIndex(version);
    const { selectedCities, refinementsCompleted, surveysSubmitted, miniTaskSurveysSubmitted, highlightedCityId, miniTaskOrder, miniTasksCompleted, miniTaskSubstep } = this.state;
    switch (steps[currentStep].pathname) {
      case 'preferences':
        return false; //selectedCities.size >= CITY_SELECTION_LOWER_BOUND && selectedCities.size <= CITY_SELECTION_UPPER_BOUND;
      case 'recommendations':
        return false; //surveySubmitted;
      //case 'critiquing-9f2c3dc5':
      //  return refinementsCompleted;
        
      case 'scaffolding-9f2c3dc5':
      case 'scaffolding-4cc20c83':
      case 'scaffolding-64f91682':
        return this.state.scaffoldingSubstep >= scaffoldingConfig(version).length - 1;
      case 'critiquing-9f2c3dc5':
      case 'critiquing-4cc20c83':
      case 'critiquing-64f91682':
      case 'critiquing2-9f2c3dc5':
      case 'critiquing2-4cc20c83':
      case 'critiquing2-64f91682':
        console.log(highlightedCityId)
        return highlightedCityId !== undefined;
      case 'final-recommendation0':
        return surveysSubmitted[0];
      case 'final-recommendation':
        return surveysSubmitted[1];
      case 'final-recommendation2':
        return surveysSubmitted[2];
      case 'critiquing3-9f2c3dc5':
      case 'critiquing3-4cc20c83':
      case 'critiquing3-64f91682':      
        return miniTaskSubstep >= miniTasks[miniTaskOrder[  miniTasksCompleted  ]].promptQuestionPairs.length;
      case 'final-recommendation3':
      default:
        return false;
    }
  }

  setRefinementsCompletedFlag = refinementsCompleted => {
    this.setState({ refinementsCompleted });
  }

  toggleCitySelection = cityId => {
    const selectedCities = new Set(this.state.selectedCities);
    let shouldShowSelectionWarning;
    if (selectedCities.has(cityId)) {
      selectedCities.delete(cityId);
      shouldShowSelectionWarning = false;
    } else {
      if (selectedCities.size < CITY_SELECTION_UPPER_BOUND) {
        selectedCities.add(cityId);
      } else {
        shouldShowSelectionWarning = true;
      }
    }

    this.setState({ selectedCities, shouldShowSelectionWarning });
  }

  handleRefinementAction = (aspect, value) => {
    const { refinements } = this.state;
    this.setState({ refinements: { ...refinements, [aspect]: value } });
  }

  getSteps = (version, taskOrder) => {
    const { match, location } = this.props;
    const code = this.getCode();

    if (version === 1) {// NO Critiquing
      return [
        {
          name: '1. Tell us your general preferences',
          pathname: 'preferences',
          onNextClick: () => { this.getRecommendations(); this.goToNextStep(`${match.path}/recommendations${location.search}`) },
          nextButtonText: 'next step',
          disabledMessage: `Please select from ${CITY_SELECTION_LOWER_BOUND} to ${CITY_SELECTION_UPPER_BOUND} (at most) cities before proceeding!`
        },
        {
          name: '2. Get recommendations',
          pathname: 'recommendations',
          onNextClick: () => { this.setState(initialState); this.goToNextStep(`${match.path}/preferences?v=2&sv=short${code ? `&c=${code}` : ''}`); },
          nextButtonText: 'start over',
          disabledMessage: 'Please complete the survey first! We greatly appreciate your help in our research.'
        },
      ];
    }
    /*else if (version === "9f2c3dc5") { // Unit Critiquing
      return [
        {
          name: '1. Tell us your general preferences',
          pathname: 'preferences',
          onNextClick: () => { this.getRecommendations(); this.goToNextStep(`${match.path}/critiquing-9f2c3dc5${location.search}`) },
          nextButtonText: 'next step',
          disabledMessage: `Please select from ${CITY_SELECTION_LOWER_BOUND} to ${CITY_SELECTION_UPPER_BOUND} (at most) cities before proceeding!`
        },
        {
          name: '2. Refine your preferences',
          pathname: 'critiquing-9f2c3dc5',
          onNextClick: () => { this.goToNextStep(`${match.path}/final-recommendation${location.search}`) },
          nextButtonText: '',
          disabledMessage: ''
        },
        {
          name: '3. Get recommendations',
          pathname: 'final-recommendation',
          onNextClick: () => { this.setState(initialState); this.goToNextStep(`${match.path}/preferences?v=2&sv=short${code ? `&c=${code}` : ''}`); },
          nextButtonText: 'start over',
          disabledMessage: 'Please complete the survey first! We greatly appreciate your help in our research.'
        },
      ];
    }*/
    else if (version === "9f2c3dc5" || version === "4cc20c83" || version === "64f91682") { // Conversational Critiqing with Utility Function
      console.log("task order: "+taskOrder)
      const taskIndices = taskOrder === "ad6283" ? ["a","b"] : ["b","a"];
      return [
        /*
        {
          name: '1. Tell us your general preferences',
          pathname: 'preferences',
          onNextClick: () => { this.getInitialRecommendationWithCritiques(); this.goToNextStep(`${match.path}/critiquing-4cc20c83${location.search}`) },
          nextButtonText: 'next step',
          disabledMessage: `Please select from ${CITY_SELECTION_LOWER_BOUND} to ${CITY_SELECTION_UPPER_BOUND} (at most) cities before proceeding!`
        },
        */
        {
          name: '1. Initial Questionnaire',
          pathname: 'final-recommendation0',
          onNextClick: () => { 
            //this.endTimeTracking();  // does not work because asynchronous
            //this.endClickTracking();  // does not work because asynchronous
            this.goToNextStep(`${match.path}/scaffolding-${version}?v=${version}&t=${taskOrder}${code ? `&c=${code}` : ''}`); 
          },
          nextButtonText: 'start intro',
          disabledMessage: 'Please complete the survey first! We greatly appreciate your help in our research.'
        },
        {
          name: '2. Introduction',
          pathname: `scaffolding-${version}`,
          onNextClick: () => { 
            this.setState({...initialState, scaffoldingStats: {scaffoldingTimeTaken: (new Date()).getTime() - this.state.startTime, scaffoldingClicksTaken: this.clickCountRef.current - this.state.startClicks}, 
            clickCount: this.state.clickCount, allCities: this.state.allCities, taskDescription: taskDescriptions[taskIndices[0]]}); 
            this.goToNextStep(`${match.path}/critiquing-${version}${location.search}`); // or use `?v=${version}&t=${taskOrder}${code ? `&c=${code}` : ''}` instead of location.search?
          },
          nextButtonText: 'start task',
          disabledMessage: 'Please complete this introduction first.'
        },
        {
          name: '3. Free Task',
          pathname: `critiquing-${version}`,
          onNextClick: () => { this.goToNextStep(`${match.path}/final-recommendation${location.search}`) },
          nextButtonText: `confirm selection`,
          disabledMessage: ''
        },
        {
          name: '4. Free Task Questionnaire',
          pathname: 'final-recommendation',
          onNextClick: () => { 
            const firstMiniTask = miniTasks[this.state.miniTaskOrder[  this.state.miniTasksCompleted  ]]; 
            this.setState({...initialState, clickCount: this.state.clickCount, allCities: this.state.allCities, miniTaskOrder: this.state.miniTaskOrder, miniTasksCompleted: this.state.miniTasksCompleted, 
            chosenAttributeOrder: firstMiniTask.chosenAttributeOrder, sliderStates: firstMiniTask.sliderStates}); 
            this.goToNextStep(`${match.path}/critiquing3-${version}${location.search}`); 
          },
          nextButtonText: 'start mini tasks',
          disabledMessage: 'Please complete the survey first! We greatly appreciate your help in our research.'
        },
        {
          name: '5. Mini-Tasks',
          pathname: `critiquing3-${version}`,
          onNextClick: () => { 
            if (this.state.miniTasksCompleted === 2 /* before state update, i.e. now the third has been completed */) { 
              /*
              this.setState({...initialState, allCities: this.state.allCities, taskDescription: taskDescriptions[taskIndices[0]]}); 
              this.goToNextStep(`${match.path}/critiquing2-${version}${location.search}`); // or use `?v=${version}&t=${taskOrder}${code ? `&c=${code}` : ''}` instead of location.search?
              */
              this.goToNextStep(`${match.path}/final-recommendation3${location.search}`);
            } 
            else { 
              const nextMiniTask = miniTasks[this.state.miniTaskOrder[  this.state.miniTasksCompleted + 1 ]]; 
              this.setState({ miniTasksCompleted: this.state.miniTasksCompleted + 1, highlightedCityId: undefined, miniTaskSubstep: 0,
              chosenAttributeOrder: nextMiniTask.chosenAttributeOrder, sliderStates: nextMiniTask.sliderStates }); 
            } 
          },
          nextButtonText: 'next', //'start final questionnaire',
          disabledMessage: 'Please follow the steps above.'
        },       
        /* 
        {
          name: '4. Free Task',
          pathname: `critiquing2-${version}`,
          onNextClick: () => { this.goToNextStep(`${match.path}/final-recommendation2${location.search}`) },
          nextButtonText: `confirm selection`,
          disabledMessage: ''
        },
        {
          name: '5. Free Task Questionnaire',
          pathname: 'final-recommendation2',
          onNextClick: () => { this.setState({...initialState, allCities: this.state.allCities, taskDescription: taskDescriptions[taskIndices[1]]}); this.goToNextStep(`${match.path}/critiquing2-${version}?v=${version}&t=${taskOrder}${code ? `&c=${code}` : ''}`); },
          nextButtonText: 'start next task',
          disabledMessage: 'Please complete the survey first! We greatly appreciate your help in our research.'
        },
        */
        {
          name: '6. Final Questionnaire',
          pathname: 'final-recommendation3',
          onNextClick: () => { /*this.setState({...initialState, allCities: this.state.allCities, taskDescription: taskDescriptions[taskIndices[0]]}); this.goToNextStep(`${match.path}/critiquing-${version}?v=${version}&t=${taskOrder}&sv=short${code ? `&c=${code}` : ''}`);*/ },
          nextButtonText: '' /*'start over'*/,
          disabledMessage: 'There is nothing left to do except completing the final questionnaire! We greatly appreciate your help in our research.'
        },
      ];
    }
    /*else if (version === "64f91682") {// Conversational Critiqing with Utility Function and Eliminations
      return [
        {
          name: '1. Tell us your general preferences',
          pathname: 'preferences',
          onNextClick: () => { this.getInitialRecommendationWithCritiques(); this.goToNextStep(`${match.path}/critiquing-64f91682${location.search}`) },
          nextButtonText: 'next step',
          disabledMessage: `Please select from ${CITY_SELECTION_LOWER_BOUND} to ${CITY_SELECTION_UPPER_BOUND} (at most) cities before proceeding!`
        },
        {
          name: '2. Refine your preferences',
          pathname: 'critiquing-64f91682',
          onNextClick: () => { this.goToNextStep(`${match.path}/final-recommendation${location.search}`) },
          nextButtonText: '',
          disabledMessage: ''
        },
        {
          name: '3. Get recommendations',
          pathname: 'final-recommendation',
          onNextClick: () => { this.setState(initialState); this.goToNextStep(`${match.path}/preferences?v=${version}&sv=short${code ? `&c=${code}` : ''}`); },
          nextButtonText: 'start over',
          disabledMessage: 'Please complete the survey first! We greatly appreciate your help in our research.'
        },
      ];
    }*/
    else {
      return [
        {
          name: 'Your are lost!!!',
          pathname: '/',
          nextButtonText: 'next step',
          disabledMessage: `LOST!`
        }
      ];
    }
  }

  render() {
    const { cities, recommendations, statistics, selectedCities, refinements, isLoading, shouldShowSelectionWarning, surveysSubmitted, miniTaskSurveysSubmitted } = this.state;
    const version = this.getVersion();
    const taskOrder = this.getTaskOrder();

    /*const RenderedPreferencesPage = props =>
      <PreferencesPage {...props}
        cities={cities}
        getCities={this.getCities}
        toggleCitySelection={this.toggleCitySelection}
        selectedCities={selectedCities}
        isLoading={isLoading}
        startTimeTracking={this.startTimeTracking}
        resetSelectedCities={this.resetSelectedCities}
        recordCityRefresh={this.recordCityRefresh}
      />;
    const RenderedRefiningPage = props =>
      <RefiningPage {...props}
        cities={recommendations}
        refinements={refinements}
        handleRefinementAction={this.handleRefinementAction}
        resetRecommenderProgress={this.resetRecommenderProgress}
        shouldResetRecommenderProgress={this.shouldResetRecommenderProgress}
        isLoading={isLoading}
        setRefinementsCompletedFlag={this.setRefinementsCompletedFlag}
        onNextStepClick={this.getSteps("9f2c3dc5")[1].onNextClick}
        onCritiqueClick={this.refineRecommendations}
      />;
    const RenderedRecommendationsPage = props =>
      <RecommendationsPage {...props}
        cities={recommendations}
        resetRecommenderProgress={this.resetRecommenderProgress}
        shouldResetRecommenderProgress={this.shouldResetRecommenderProgress}
        submitSurvey={this.submitSurvey}
        isLoading={isLoading}
        endTimeTracking={this.endTimeTracking}
        surveySubmitted={surveySubmitted}
      />;*/
    const RenderedScaffoldingPage = props =>
      <CritiquingPage {...props}
        cities={recommendations}
        allCities={this.state.allCities}
        highlightedCityId={this.state.highlightedCityId}
        setHighlightedCityId={highlightedCityId => this.setState({highlightedCityId})}
        statistics={statistics}
        refinements={refinements}
        handleRefinementAction={this.handleRefinementAction}
        resetRecommenderProgress={this.resetRecommenderProgress}
        shouldResetRecommenderProgress={this.shouldResetRecommenderProgress}
        isLoading={isLoading}
        setRefinementsCompletedFlag={this.setRefinementsCompletedFlag}
        onNextStepClick={this.getSteps(version, taskOrder)[1].onNextClick}
        onCritiqueClick={this.getRecommendationWithCritiques}
        version={version}

        startTimeTracking={this.startTimeTracking}
        startClickTracking={this.startClickTracking}
        clickCount={this.state.clickCount}
        clickCountRef={this.clickCountRef}

        isScaffolding={true}
        scaffoldingSubstep={this.state.scaffoldingSubstep}
        setScaffoldingSubstep={scaffoldingSubstep => this.setState({scaffoldingSubstep})}
        scaffoldingConfig={scaffoldingConfig(version)}

        chosenAttributeOrder={this.state.chosenAttributeOrder}
        setChosenAttributeOrder={chosenAttributeOrder => this.setState({chosenAttributeOrder})}
        sliderStates={this.state.sliderStates}
        setSliderStates={sliderStates => this.setState({sliderStates})}
        hiddenAttrKeys={this.state.hiddenAttrKeys}
        setHiddenAttrKeys={hiddenAttrKeys => this.setState({hiddenAttrKeys})}
      />;
    const RenderedMiniTaskCritiquingPage = props =>
      <CritiquingPage {...props}
        cities={recommendations}
        allCities={this.state.allCities}
        highlightedCityId={this.state.highlightedCityId}
        setHighlightedCityId={highlightedCityId => this.setState({highlightedCityId})}
        statistics={statistics}
        refinements={refinements}
        handleRefinementAction={this.handleRefinementAction}
        resetRecommenderProgress={this.resetRecommenderProgress}
        shouldResetRecommenderProgress={this.shouldResetRecommenderProgress}
        isLoading={isLoading}
        setRefinementsCompletedFlag={this.setRefinementsCompletedFlag}
        onNextStepClick={this.getSteps(version, taskOrder)[4].onNextClick}
        onCritiqueClick={this.getRecommendationWithCritiques}
        version={version}

        startTimeTracking={this.startTimeTracking}
        startClickTracking={this.startClickTracking}
        endTimeTracking={this.endTimeTracking}
        endClickTracking={this.endClickTracking}
        clickCount={this.state.clickCount}
        clickCountRef={this.clickCountRef}

        miniTask={miniTasks[this.state.miniTaskOrder[  this.state.miniTasksCompleted  ]]}
        miniTasksCompleted={this.state.miniTasksCompleted}
        miniTaskSubstep={this.state.miniTaskSubstep}
        setMiniTaskSubstep={miniTaskSubstep => this.setState({miniTaskSubstep})}

        lastMiniTask={this.state.miniTasksCompleted === this.state.miniTaskOrder.length - 1}
        submitMiniTaskSurvey={this.submitMiniTaskSurvey}

        chosenAttributeOrder={this.state.chosenAttributeOrder}
        setChosenAttributeOrder={chosenAttributeOrder => this.setState({chosenAttributeOrder})}
        sliderStates={this.state.sliderStates}
        setSliderStates={sliderStates => this.setState({sliderStates})}
        hiddenAttrKeys={this.state.hiddenAttrKeys}
        setHiddenAttrKeys={hiddenAttrKeys => this.setState({hiddenAttrKeys})}
      />;    
    const RenderedCritiquingPage = props =>
      <CritiquingPage {...props}
        cities={recommendations}
        allCities={this.state.allCities}
        highlightedCityId={this.state.highlightedCityId}
        setHighlightedCityId={highlightedCityId => this.setState({highlightedCityId})}
        statistics={statistics}
        refinements={refinements}
        handleRefinementAction={this.handleRefinementAction}
        resetRecommenderProgress={this.resetRecommenderProgress}
        shouldResetRecommenderProgress={this.shouldResetRecommenderProgress}
        isLoading={isLoading}
        setRefinementsCompletedFlag={this.setRefinementsCompletedFlag}
        // onNextStepClick={() => {}} not used anymore, dead code
        onCritiqueClick={this.getRecommendationWithCritiques}
        version={version}
        startTimeTracking={this.startTimeTracking}
        startClickTracking={this.startClickTracking}
        clickCount={this.state.clickCount}
        clickCountRef={this.clickCountRef}

        chosenAttributeOrder={this.state.chosenAttributeOrder}
        setChosenAttributeOrder={chosenAttributeOrder => this.setState({chosenAttributeOrder})}
        sliderStates={this.state.sliderStates}
        setSliderStates={sliderStates => this.setState({sliderStates})}
        hiddenAttrKeys={this.state.hiddenAttrKeys}
        setHiddenAttrKeys={hiddenAttrKeys => this.setState({hiddenAttrKeys})}
      />;
      /*const RenderedCritiquingEliminationPage = props =>
      <CritiquingPage {...props}
        cities={recommendations}
        statistics={statistics}
        refinements={refinements}
        handleRefinementAction={this.handleRefinementAction}
        resetRecommenderProgress={this.resetRecommenderProgress}
        shouldResetRecommenderProgress={this.shouldResetRecommenderProgress}
        isLoading={isLoading}
        setRefinementsCompletedFlag={this.setRefinementsCompletedFlag}
        onNextStepClick={this.getSteps("64f91682")[1].onNextClick}
        onCritiqueClick={this.getRecommendationWithEliminationCritiques}
      />;*/
    const RenderedFinalRecommendationPage = whichQuestionnaire => props =>
      <FinalRecommendationPage {...props}
        cities={recommendations}
        highlightedCity={this.state.allCities.filter(c => c.id === this.state.highlightedCityId)[0]}
        resetRecommenderProgress={this.resetRecommenderProgress}
        shouldResetRecommenderProgress={this.shouldResetRecommenderProgress}
        isLoading={isLoading}

        whichQuestionnaire={whichQuestionnaire}

        submitSurvey={this.submitSurvey}

        surveysSubmitted={surveysSubmitted}
        endTimeTracking={this.endTimeTracking}
        endClickTracking={this.endClickTracking}
        clickCount={this.state.clickCount}
        clickCountRef={this.clickCountRef}

        onNextStepClick={this.getSteps(version, taskOrder)[whichQuestionnaire === "initial" ? 0 : whichQuestionnaire === "afterFreeTask" ? 3 : 5].onNextClick}
      />;


    const withTaskDescriptionAndConfirmation = (renderedPage) => props =>
      <>
      {this.state.taskDescription === undefined ? <></> :
      <StandardContainer style={{flexShrink: 0, flexGrow: 0, width: "900px", padding: 0, position: "relative", backgroundColor: "white", color: "black", marginBottom: "20px"}}>{/* 1 1 is default*/}
        <div style={{position: "absolute", top: "-24px", left: 0}}>YOUR TASK IS:</div>
        <div style={{border: "2px solid black", boxShadow: "0 5px 11px 0 #80808080", padding: "10px 20px", margin: "0", textAlign: "center"}}>
          <p style={{fontWeight: "bold", fontSize: "110%"}}>{this.state.taskDescription}</p>
          <div style={{fontSize: "70%"}}>Focus only on the city's attributes, not on how far away it is or how expensive the journey might be. If you like, you can imagine one could teleport to that city in an instant. If you are not sure what to look for, start by exploring this system freely.</div>
        </div>
      </StandardContainer>
      }
      {this.state.confirmed !== true ? <div style={{flex: 1, margin: "0 auto"}}><button onClick={() => this.setState({confirmed: true})}>UNDERSTOOD</button></div> : regular(renderedPage)(props)}
      </>
      
      const withTaskDescription = (renderedPage) => props =>
      <>
      {this.state.taskDescription === undefined ? <></> :
      <StandardContainer style={{flexShrink: 0, flexGrow: 0, width: "900px", padding: 0, position: "relative", backgroundColor: "white", color: "black", marginBottom: "20px"}}>{/* 1 1 is default*/}
        <div style={{position: "absolute", top: "-24px", left: 0}}>YOUR TASK IS:</div>
        <div style={{border: "2px solid black", boxShadow: "0 5px 11px 0 #80808080", padding: "10px 20px", margin: "0", textAlign: "center"}}>
          <p style={{fontWeight: "bold", fontSize: "110%"}}>{this.state.taskDescription}</p>
          <div style={{fontSize: "70%"}}>Focus only on the city's attributes, not on how far away it is or how expensive the journey might be. If you like, you can imagine you could teleport to that city in an instant. If you are not sure what to look for, start by exploring this system freely.</div>
        </div>
      </StandardContainer>
      }
      {regular(renderedPage)(props)}
      </>
      
      const withMiniTaskDescription = (renderedPage) => props =>
      <>
      <StandardContainer style={{flexShrink: 0, flexGrow: 0, width: "900px", padding: 0, position: "relative", backgroundColor: "white", color: "black", marginBottom: "20px"}}>{/* 1 1 is default*/}
        <div style={{position: "absolute", top: "-24px", left: 0}}>YOUR TASK IS:</div>
        <div style={{border: "2px solid black", boxShadow: "0 5px 11px 0 #80808080", padding: "10px 20px", margin: "0", textAlign: "center"}}>
          <p style={{fontWeight: "bold", fontSize: "110%"}}>{miniTasks[this.state.miniTaskOrder[this.state.miniTasksCompleted]].taskDescription}</p>
          <div style={{fontSize: "70%"}}>Help your friend find the best matching cities by following the steps below.</div>
        </div>
      </StandardContainer>
      {regular(renderedPage)(props)}
      </>
  

    const regular = (renderedPage) => props =>
      <StandardContainer style={{flexShrink: 1, flexGrow: 1, width: "100%", maxWidth: "100%", overflowY: "auto"}}>{/* 1 1 is default, overflow-y auto prevents height increase to more than 100% */}
        {renderedPage(props)}
      </StandardContainer>
    

    return (
      <React.Fragment>
        <Notification
          isActive={shouldShowSelectionWarning}
          message={`You can select at most ${CITY_SELECTION_UPPER_BOUND} cities. Try deselecting some cities!`}
          action="Dismiss"
          title="Warning"
          dismissAfter={3500}
          onDismiss={() => this.setState({ shouldShowSelectionWarning: false })}
          onClick={() => this.setState({ shouldShowSelectionWarning: false })}
        />
        <Switch>
          {/*<Route path={`${this.props.match.path}/preferences`} render={RenderedPreferencesPage} />*/}
          {/*<Route path={`${this.props.match.path}/recommendations`} render={RenderedRecommendationsPage} />*/}

          <Route path={`${this.props.match.path}/final-recommendation0`} render={regular(RenderedFinalRecommendationPage("initial"))} />
          <Route path={`${this.props.match.path}/scaffolding-9f2c3dc5`} render={regular(RenderedScaffoldingPage)} />
          <Route path={`${this.props.match.path}/scaffolding-4cc20c83`} render={regular(RenderedScaffoldingPage)} />
          <Route path={`${this.props.match.path}/scaffolding-64f91682`} render={regular(RenderedScaffoldingPage)} />

          <Route path={`${this.props.match.path}/critiquing-9f2c3dc5`} render={withTaskDescriptionAndConfirmation(RenderedCritiquingPage)} />
          <Route path={`${this.props.match.path}/critiquing-4cc20c83`} render={withTaskDescriptionAndConfirmation(RenderedCritiquingPage)} />
          <Route path={`${this.props.match.path}/critiquing-64f91682`} render={withTaskDescriptionAndConfirmation(RenderedCritiquingPage)} />
          <Route path={`${this.props.match.path}/final-recommendation`} render={withTaskDescription(RenderedFinalRecommendationPage("afterFreeTask"))} />

          <Route path={`${this.props.match.path}/critiquing2-9f2c3dc5`} render={withTaskDescriptionAndConfirmation(RenderedCritiquingPage)} />
          <Route path={`${this.props.match.path}/critiquing2-4cc20c83`} render={withTaskDescriptionAndConfirmation(RenderedCritiquingPage)} />
          <Route path={`${this.props.match.path}/critiquing2-64f91682`} render={withTaskDescriptionAndConfirmation(RenderedCritiquingPage)} />
          <Route path={`${this.props.match.path}/final-recommendation2`} render={withTaskDescription(RenderedFinalRecommendationPage("afterFreeTask"))} />
          
          <Route path={`${this.props.match.path}/critiquing3-9f2c3dc5`} render={withMiniTaskDescription(RenderedMiniTaskCritiquingPage)} />
          <Route path={`${this.props.match.path}/critiquing3-4cc20c83`} render={withMiniTaskDescription(RenderedMiniTaskCritiquingPage)} />
          <Route path={`${this.props.match.path}/critiquing3-64f91682`} render={withMiniTaskDescription(RenderedMiniTaskCritiquingPage)} />

          <Route path={`${this.props.match.path}/final-recommendation3`} render={regular(RenderedFinalRecommendationPage("afterMiniTasks"))} />

          <Redirect to={`${this.props.match.path}/`} />
        </Switch>
        <Stepper surveysSubmitted={this.state.surveysSubmitted} miniTaskSurveysSubmitted={this.state.miniTaskSurveysSubmitted} style={{flexShrink: 0, flexGrow: 0, position: "static"}} steps={this.getSteps(version, taskOrder)} currentStep={this.getCurrentStepIndex(version, taskOrder)} nextStepEnabled={this.nextStepEnabled(version, taskOrder)} />
      </React.Fragment>
    );
  }
}

export default Recommender;