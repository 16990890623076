const climateTypeToName = {
  Af: "Tropical rainforest",
  Am: "Tropical wet",
  Aw: "Tropical wet and dry",
  As: "Tropical savanna",
  BWh: "Desert",
  BWk: "Desert",
  BSh: "Semi-arid",
  BSk: "Tropical and subtropical steppe",
  Csa: "Mediterrean",
  Csc: "Mediterrean",
  Csb: "Mediterrean",
  Cwa: "Humid subtropical",
  Cwb: "Oceanic subtropical highland",
  Cwc: "Oceanic subtropical highland",
  Cfa: "Humid subtropical",
  Cfb: "Marine west coast",
  Cfc: "Marine west coast",
  Dsa: "Humid continental",
  Dsb: "Humid continental",
  Dsc: "Subarctic",
  Dsd: "Subarctic",
  Dwa: "Humid continental",
  Dwb: "Humid continental",
  Dwc: "Subarctic",
  Dwd: "Subarctic",
  Dfa: "Hot summer continental",
  Dfb: "Humid continental",
  Dfc: "Continental subarctic",
  Dfd: "Subarctic",
  ET: "Tundra",
  EF: "Ice cap",
}

class City {
  id;
  name;
  country;
  pictureUrl;
  climateType;

  averageTemperature;
  costOfLivingIndexBin;

  foodScaled;
  nightlifeScaled;
  artsAndEntertainmentScaled;
  outdoorsAndRecreationScaled;
  averageTemperatureScaled;
  costOfLivingIndexScaled;
  averagePrecipitationScaled;
  venueCountScaled;
  //travelAndTransportScaled;
  //shopsAndServicesScaled;

  foodRank;
  nightlifeRank;
  artsAndEntertainmentRank;
  outdoorsAndRecreationRank;
  averageTemperatureRank;
  costOfLivingIndexRank;
  averagePrecipitationRank;
  venueCountRank;
  //travelAndTransportRank;
  //shopsAndServicesRank;
  
  distance;
  currentRecommendation;
  critiquingCity;

  constructor({
    id, 
    name, 
    country, 
    pictureUrl, 
    climateType, 
    
    averageTemperature, 
    costOfLivingIndexBin, 
    
    foodScaled, 
    nightlifeScaled,
    artsAndEntertainmentScaled, 
    outdoorsAndRecreationScaled, 
    averageTemperatureScaled, 
    costOfLivingIndexScaled,
    averagePrecipitationScaled,
    venueCountScaled,
    //travelAndTransportScaled, 
    //shopsAndServicesScaled, 

    foodRank, 
    nightlifeRank, 
    artsAndEntertainmentRank, 
    outdoorsAndRecreationRank, 
    averageTemperatureRank, 
    costOfLivingIndexRank, 
    averagePrecipitationRank, 
    venueCountRank,
    //travelAndTransportRank, 
    //shopsAndServicesRank, 
    
    distance
  }) {
    this.id = id;
    this.name = name;
    this.country = country;
    this.pictureUrl = pictureUrl;
    this.climateType = climateType;

    this.averageTemperature = averageTemperature;
    this.costOfLivingIndexBin = costOfLivingIndexBin;

    this.foodScaled = foodScaled;
    this.nightlifeScaled = nightlifeScaled;
    this.artsAndEntertainmentScaled = artsAndEntertainmentScaled;
    this.outdoorsAndRecreationScaled = outdoorsAndRecreationScaled;
    this.averageTemperatureScaled = averageTemperatureScaled;
    this.costOfLivingIndexScaled = costOfLivingIndexScaled;
    this.averagePrecipitationScaled = averagePrecipitationScaled;
    this.venueCountScaled = venueCountScaled;
    //this.travelAndTransportScaled = travelAndTransportScaled;
    //this.shopsAndServicesScaled = shopsAndServicesScaled;

    //lowest rank has the highest feature value in database. We are transforming the rank to match with our
    // critiquing UI, where lowest value has the lowest rank
    
    this.foodRank = 181 - foodRank;
    this.nightlifeRank = 181 - nightlifeRank;
    this.artsAndEntertainmentRank = 181 - artsAndEntertainmentRank;
    this.outdoorsAndRecreationRank = 181 - outdoorsAndRecreationRank;
    this.averageTemperatureRank = 181 - averageTemperatureRank;
    this.costOfLivingIndexRank = 181 - costOfLivingIndexRank;
    this.averagePrecipitationRank = 181 - averagePrecipitationRank;
    this.venueCountRank = 181 - venueCountRank;
    //this.travelAndTransportRank = 181 - travelAndTransportRank;
    //this.shopsAndServicesRank = 181 - shopsAndServicesRank;

    this.distance = distance;
    this.currentRecommendation = false;
    this.critiquingCity = false;
  }

  static parse = data => {
    const city = new City({
      id: data._id,
      name: data.name,
      country: data.country,
      pictureUrl: data.pictureUrl !== 'NaN' ? data.pictureUrl : 'https://source.unsplash.com/aExT3y92x5o/500x300',
      climateType: climateTypeToName[data.climateType],

      averageTemperature: data.averageTemperature,
      costOfLivingIndexBin: data.costOfLivingIndexBin,

      foodScaled: data.foodScaled,
      nightlifeScaled: data.nightlifeScaled,
      artsAndEntertainmentScaled: data.artsAndEntertainmentScaled,
      outdoorsAndRecreationScaled: data.outdoorsAndRecreationScaled,
      averageTemperatureScaled: data.averageTemperatureScaled,
      costOfLivingIndexScaled: data.costOfLivingIndexScaled,
      averagePrecipitationScaled: data.averagePrecipitationScaled,
      venueCountScaled: data.venueCountScaled,
      //travelAndTransportScaled: data.travelAndTransportScaled,
      //shopsAndServicesScaled: data.shopsAndServicesScaled,

      foodRank: data.foodRank,
      nightlifeRank: data.nightlifeRank,
      artsAndEntertainmentRank: data.artsAndEntertainmentRank,
      outdoorsAndRecreationRank: data.outdoorsAndRecreationRank,
      averageTemperatureRank: data.averageTemperatureRank,
      costOfLivingIndexRank: data.costOfLivingIndexRank,
      averagePrecipitationRank: data.averagePrecipitationRank,
      venueCountRank: data.venueCountRank,
      //travelAndTransportRank: data.travelAndTransportRank,
      //shopsAndServicesRank: data.shopsAndServicesRank,

      distance: data.distance
    });
    
    return city;
  }
}

export default City;