import React from 'react';
import { PageTitle, StandardRow, StandardLargeContainer } from '../../../CommonStyles';
import { CenteredContainer } from '../styles';
import CityDetailsPane from './CityDetailsPane';
import { HintMessage, SurveyFootnote } from './styles';
import Spinner from '../../../components/Spinner';
import * as Survey from "survey-react";
import "survey-react/survey.css";
import queryString from 'query-string';
import once from 'lodash/once';
import isEqual from 'lodash/isEqual';

import slider1 from './slider1.png';
import slider2 from './slider2.png';
import slider3 from './slider3.png';


const questionsSetInitial = {
  elements: [
    { type: "radiogroup",
      name: "gender",
      title: "Please select your gender.",
      isRequired: true,
      choices: [
        'Female',
        'Male',
        'Other',
        'Prefer not to disclose',
      ]
    },
    { type: "radiogroup",
      name: "ageGroup",
      title: "Please select your age group.",
      isRequired: true,
      choices: [
        '-20',
        '21-30',
        '31-40',
        '41-50',
        '51-60',
        '61-',
        'Prefer not to disclose',
      ]
    },    
    { type: "radiogroup",
    name: "degree",
    title: "Please select your highest educational degree.",
    isRequired: true,
    choices: [
      'High-school diploma',
      'Higher education entrance qualification',
      'University degree',
      'None of the above',
      'Prefer not to disclose',
    ]
  },
    { type: "radiogroup",
      name: "recommenderFamiliarity",
      title: "Please indicate your level of agreement to this statement: I have a good understanding of the concepts behind recommender systems.",
      isRequired: true,
      choices: [
        'Strongly Disagree',
        'Disagree',
        'Neutral',
        'Agree',
        'Strongly Agree',
      ]
    },    { type: "radiogroup",
      name: "travelingFrequency",
      title: "How often do you usually travel for vacation? (not considering Covid-19 restrictions)",
      isRequired: true,
      choices: [
        'Less than once a year',
        'Once a year',
        '2-4 times a year',
        '5-7 times a year',
        'More than 7 times a year',
      ]
    },
    {
      type: "matrix",
      name: "rationalIntuitive",
      title: "* Please indicate your level of agreement with the following statements.",
      isAllRowRequired: true,
      columns: [
        {
          value: "Strongly Disagree",
          text: "Strongly Disagree"
        },
        {
          value: "Disagree",
          text: "Disagree"
        },
        {
          value: "Neutral",
          text: "Neutral"
        },
        {
          value: "Agree",
          text: "Agree"
        },
        {
          value: "Strongly Agree",
          text: "Strongly Agree"
        }
      ],
      rows: [
        { value: "rational1", text: "I prefer to gather all the necessary information before committing to a decision." },
        { value: "rational2", text: "I thoroughly evaluate decision alternatives before making a final choice." },
        { value: "rational3", text: "In decision making, I take time to contemplate the pros/cons or risks/benefits of a situation." },
        { value: "rational4", text: "Investigating the facts is an important part of my decision-making process." },
        { value: "rational5", text: "I weigh a number of different factors when making decisions." },
        { value: "intuitive1", text: "When making decisions, I rely mainly on my gut feelings." },
        { value: "intuitive2", text: "My initial hunch about decisions is generally what I follow." },
        { value: "intuitive3", text: "I make decisions based on intuition." },
        { value: "intuitive4", text: "I rely on my first impressions when making decisions." },
        { value: "intuitive5", text: "I weigh feelings more than analysis in making decisions." },
      ]
    },
    {
      type: "matrix",
      name: "visualFamiliarityAndATI",
      title: "* Please indicate your level of agreement with the following statements.",
      isAllRowRequired: true,
      columns: [
        {
          value: "Strongly Disagree",
          text: "Strongly Disagree"
        },
        {
          value: "Disagree",
          text: "Disagree"
        },
        {
          value: "Neutral",
          text: "Neutral"
        },
        {
          value: "Agree",
          text: "Agree"
        },
        {
          value: "Strongly Agree",
          text: "Strongly Agree"
        }
      ],
      rows: [
        { value: "visualFamiliarity1", text: "I am competent when it comes to graphing and tabulating data." },
        { value: "visualFamiliarity2", text: "I frequently tabulate data with computer software." },
        { value: "visualFamiliarity3", text: "I have graphed a lot of data in the past." },
        { value: "visualFamiliarity4", text: "I frequently analyze data visualizations." },
        { value: "ATI1", text: "I enjoy spending time becoming acquainted with a new technical system." },
        { value: "ATI2", text: "When I have a new technical system in front of me, I try it out intensively." },
        { value: "ATI3", text: "I like testing the functions of new technical systems." },
        { value: "attentionCheck1", text: "Please select \"Disagree\" here." },
        { value: "ATI4", text: "I like to occupy myself in greater detail with technical systems." },
        { value: "ATI5", text: "I try to make full use of the capabilities of a technical system." },
        { value: "ATI6", text: "I try to understand how a technical system exactly works." },
        { value: "ATI7", text: "It is enough for me to know the basic functions of a technical system." },
        { value: "ATI8", text: "It is enough for me that a technical system works; I don’t care how or why." },
        { value: "ATI9", text: "I predominantly deal with technical systems because I have to." },
      ]
    },
  ]
}

const questionsSetPostTask = {
  elements: [
    {
      type: "matrix",
      name: "satisfactionAfterFreeTask",
      title: "* Please indicate your level of agreement with the following statement.",
      isAllRowRequired: true,
      columns: [
        {
          value: "Strongly Disagree",
          text: "Strongly Disagree"
        },
        {
          value: "Disagree",
          text: "Disagree"
        },
        {
          value: "Neutral",
          text: "Neutral"
        },
        {
          value: "Agree",
          text: "Agree"
        },
        {
          value: "Strongly Agree",
          text: "Strongly Agree"
        }
      ],
      rows: [
        { value: "satisfaction1", text: "I am satisfied with my final selection." },
      ],
    },
    {
      type: "matrix",
      name: "difficultyAfterFreeTask",
      title: "* Please indicate the difficulty of your task.",
      isAllRowRequired: true,
      columns: [
        {
          value: "1",
          text: "1 (Very Difficult)"
        },
        {
          value: "2",
          text: "2"
        },
        {
          value: "3",
          text: "3"
        },
        {
          value: "4",
          text: "4"
        },
        {
          value: "5",
          text: "5"
        },
        {
          value: "6",
          text: "6"
        },
        {
          value: "7",
          text: "7 (Very Easy)"
        }
      ],
      rows: [
        { value: "difficulty1", text: "Overall, this task was ..." },
      ],
    },
    {
      type: "matrix",
      name: "sliderHelpfulnessAfterFreeTask",
      title: "* Please indicate your level of agreement with the following statements.",
      isAllRowRequired: true,
      columns: [
        {
          value: "Strongly Disagree",
          text: "Strongly Disagree"
        },
        {
          value: "Disagree",
          text: "Disagree"
        },
        {
          value: "Neutral",
          text: "Neutral"
        },
        {
          value: "Agree",
          text: "Agree"
        },
        {
          value: "Strongly Agree",
          text: "Strongly Agree"
        }
      ],
      rows: [
        //{ value: "ux1", text: "I liked using the sliders to select appropriate ranges." },
        //{ value: "ux2", text: "Selecting a city with the slider technique was a pleasant experience." },
        { value: "clarity1", text: "The information shown in the sliders was easy to understand." },
        { value: "clarity2", text: "The given information in the sliders helped me to select an appropriate range." },
        //{ value: "ease-of-use1", text: "The sliders helped me to find suitable cities quickly." },
        //{ value: "ease-of-use2", text: "I could determine suitable ranges for the sliders quickly." },
        { value: "search1", text: "Using the sliders, I was able to determine in which ranges were particularly many cities." },
        { value: "search2", text: "The sliders gave me a good insight into the distribution of the cities." },
        { value: "attentionCheck2", text: "Please select \"Strongly Agree\" here." },
        { value: "tradeoff1", text: "I was able to determine the mutual influence of multiple sliders." },
        { value: "tradeoff2", text: "The sliders helped me to understand the trade-offs between city attributes." },
        //{ value: "confidence1", text: "The sliders were helpful to find a suitable city." },
        //{ value: "confidence2", text: "The information displayed in the sliders helped me to make a decision." },
      ]
    },
    {
      type: "matrix",
      name: "transparencyAfterFreeTask",
      title: "* Please indicate your level of agreement with the following statements.",
      isAllRowRequired: true,
      columns: [
        {
          value: "Strongly Disagree",
          text: "Strongly Disagree"
        },
        {
          value: "Disagree",
          text: "Disagree"
        },
        {
          value: "Neutral",
          text: "Neutral"
        },
        {
          value: "Agree",
          text: "Agree"
        },
        {
          value: "Strongly Agree",
          text: "Strongly Agree"
        }
      ],
      rows: [
        { value: "informationProvision1", text: "The system provided information to understand why the items were recommended." },
        { value: "informationProvision2", text: "The system provided information about how my preferences were inferred." },
        { value: "informationProvision3", text: "The system provided information about how well the recommendations match my preferences." },
        
        { value: "understandingIO1", text: "It was clear to me what kind of data the system uses to generate recommendations." },
        { value: "understandingIO2", text: "I understood what data was used by the system to infer my preferences." },
        { value: "understandingIO3", text: "I understood why the items were recommended to me." },
        { value: "understandingIO4", text: "I understood how the quality of the items was determined by the system." },
        { value: "understandingIO5", text: "I understood why the system determined that the recommended items would suit me." },

        { value: "interaction1", text: "I know what actions to perform in the system so that it generates better recommendations." },
        { value: "interaction2", text: "I know what needs to be changed in order to get better recommendations." },

      ]
    },
    /*
    {
      type: "matrix",
      name: "predictorRelevance",
      title: "* Regarding the bookmarks, please indicate your level of agreement with the following statements.",
      isAllRowRequired: true,
      columns: [
        {
          value: "Strongly Disagree",
          text: "Strongly Disagree"
        },
        {
          value: "Disagree",
          text: "Disagree"
        },
        {
          value: "Neutral",
          text: "Neutral"
        },
        {
          value: "Agree",
          text: "Agree"
        },
        {
          value: "Strongly Agree",
          text: "Strongly Agree"
        }
      ], 
      rows: [
        { value: "ux3", text: "I liked using the bookmarks to keep track of interesting cities." },
        { value: "ux4", text: "Comparing cities using the bookmark function was a pleasant experience." },
        { value: "clarity3", text: "The information shown in the sliders was easy to understand." },
        { value: "clarity4", text: "The given information in the sliders helped me to select an appropriate range." },
        { value: "ease-of-use3", text: "The bookmarks helped me to find suitable cities quickly." },
        { value: "ease-of-use4", text: "I could determine suitable cities to bookmark quickly." },
        { value: "search3", text: "Using the bookmarks, I was able to determine in which ranges were particularly interesting cities." },
        { value: "search4", text: "The bookmarks gave me a good insight into the distribution of interesting cities." },
        { value: "trade-off3", text: "I was able to determine the mutual influence of multiple attributes via the bookmarks." },
        { value: "trade-off4", text: "The bookmarks helped me to understand the trade-offs between city attributes." },
        { value: "confidence3", text: "The bookmarks were helpful to find a suitable city." },
        { value: "confidence4", text: "The collection of bookmarks helped me to make a decision." },
      ],
    },
    */
    {
      "type": "comment",
      "name": "commentAfterFreeTask",
      isRequired: false,
      title: "Do you have additional comments regarding your experience during this task or the recommender system in general? (optional)"
    }
  ]
};

const questionsSetPostTest = {
  elements: [
    /* // SOME ITEMS ARE NOT PART OF THE FINAL RESQUE !!! DOUBLE-CHECK !!!
    {
      type: "matrix",
      name: "evaluation",
      title: "* Please indicate your level of agreement with the following statements.",
      isAllRowRequired: true,
      columns: [
        {
          value: "Strongly Disagree",
          text: "Strongly Disagree"
        },
        {
          value: "Disagree",
          text: "Disagree"
        },
        {
          value: "Neutral",
          text: "Neutral"
        },
        {
          value: "Agree",
          text: "Agree"
        },
        {
          value: "Strongly Agree",
          text: "Strongly Agree"
        }
      ],
      rows: [
        {
          value: "interest",
          text: "The cities recommended to me matched my interests."
        },
        {
          value: "novelty1",
          text: "The cities recommended to me are novel."
        },
        {
          value: "novelty2",
          text: "The recommender sytem helped me discover new cities."
        },
        {
          value: "diversity",
          text: "The cities recommended to me are diverse."
        },
        {
          value: "suggestions",
          text: "This recommender system gave me good suggestions."
        },
        {
          value: "interface_adequacy1",
          text: "The labels of the recommender interface were clear."
        },
        {
          value: "interface_adequacy2",
          text: "The labels of the recommender interface were adequate."
        },
        {
          value: "interface_adequacy3",
          text: "The layout of the recommender interface is attractive."
        },
        {
          value: "interface_adequacy4",
          text: "The layout of the recommender interface is adequate."
        },
        {
          value: "friend",
          text: "The recommendations I received better fits my interests than what I may receive from a friend."
        },
        {
          value: "familiar",
          text: "Some of the recommended cities are familiar to me."
        },
        {
          value: "attractive",
          text: "The cities recommended to me are attractive."
        },
        {
          value: "explanations",
          text: "The recommender explains why the cities are recommended to me."
        },
        {
          value: "sufficient",
          text: "The information provided for the recommended cities is sufficient for me to make a decision."
        },
        {
          value: "tell_like",
          text: "This recommender system allows me to tell what I like/dislike."
        },
        {
          value: "easy_like",
          text: "I found it easy to tell this recommender system what I like/dislike."
        },
        {
          value: "easy_inform",
          text: "I found it easy to inform the system if I dislike/like the recommended cities."
        },
        {
          value: "easy_modify",
          text: "I found it easy to modify my preferences in this recommender system."
        },
        {
          value: "familiar_quickly",
          text: "I became familiar with the recommender system very quickly."
        },
        {
          value: "easy_found",
          text: "I easily found the recommended items."
        },
        {
          value: "attentionCheck",
          text: "Please select \"Disagree\" for this attention check."
        },
        {
          value: "ideal",
          text: "This recommender system helped me find the ideal city to travel to."
        },
        {
          value: "influence",
          text: "This recommender system influenced my selection of cities."
        },
        {
          value: "finding",
          text: "Finding a city to travel to with the help of this recommender system is easy."
        },
        {
          value: "control1",
          text: "I feel in control of modifying my preferences."
        },
        {
          value: "control2",
          text: "The recommender allows me to modify my preferences."
        },
        {
          value: "control3",
          text: "I found it easy to modify my taste profile in the recommender."
        },
        {
          value: "transparency",
          text: "I understood why the items were recommended to me."
        },
        {
          value: "perceived_usefulness1",
          text: "The recommender helped me find the ideal city."
        },
        {
          value: "perceived_usefulness2",
          text: "Using the recommender to find what I like is easy."
        },
        {
          value: "perceived_usefulness3",
          text: "The recommender gave me good suggestions."
        },
        {
          value: "satisfied",
          text: "Overall, I am satisfied with this recommender system."
        },
        {
          value: "trust1",
          text: "I am convinced of the cities recommended to me."
        },
        {
          value: "trust2",
          text: "I am confident I will like the cities recommended to me."
        },
        {
          value: "trust3",
          text: "The recommender made me more confident about my selection/decision."
        },
        {
          value: "trust4",
          text: "The recommender can be trusted."
        },
        {
          value: "use_intention1",
          text: "I will use this recommender again."
        },
        {
          value: "use_intention2",
          text: "I will use this recommender frequently."
        },
        {
          value: "use_intention3",
          text: "I will tell my friends about this recommender."
        },
        {
          value: "purchase_intention",
          text: "I would travel to the cities recommended, given the opportunity."
        },
      ]
    },
    */
    {
      type: "matrix",
      name: "sliderHelpfulnessAfterMiniTasks",
      title: "* Please indicate your level of agreement with the following statements.",
      isAllRowRequired: true,
      columns: [
        {
          value: "Strongly Disagree",
          text: "Strongly Disagree"
        },
        {
          value: "Disagree",
          text: "Disagree"
        },
        {
          value: "Neutral",
          text: "Neutral"
        },
        {
          value: "Agree",
          text: "Agree"
        },
        {
          value: "Strongly Agree",
          text: "Strongly Agree"
        }
      ],
      rows: [
        //{ value: "ux1", text: "I liked using the sliders to select appropriate ranges." },
        //{ value: "ux2", text: "Selecting a city with the slider technique was a pleasant experience." },
        { value: "clarity1", text: "The information shown in the sliders was easy to understand." },
        { value: "clarity2", text: "The given information in the sliders helped me to select an appropriate range." },
        //{ value: "ease-of-use1", text: "The sliders helped me to find suitable cities quickly." },
        //{ value: "ease-of-use2", text: "I could determine suitable ranges for the sliders quickly." },
        { value: "search1", text: "Using the sliders, I was able to determine in which ranges were particularly many cities." },
        { value: "search2", text: "The sliders gave me a good insight into the distribution of the cities." },
        { value: "tradeoff1", text: "I was able to determine the mutual influence of multiple sliders." },
        { value: "tradeoff2", text: "The sliders helped me to understand the trade-offs between city attributes." },
        //{ value: "confidence1", text: "The sliders were helpful to find a suitable city." },
        //{ value: "confidence2", text: "The information displayed in the sliders helped me to make a decision." },
      ]
    },
    {
      type: "matrix",
      name: "transparencyAfterMiniTasks",
      title: "* Please indicate your level of agreement with the following statements.",
      isAllRowRequired: true,
      columns: [
        {
          value: "Strongly Disagree",
          text: "Strongly Disagree"
        },
        {
          value: "Disagree",
          text: "Disagree"
        },
        {
          value: "Neutral",
          text: "Neutral"
        },
        {
          value: "Agree",
          text: "Agree"
        },
        {
          value: "Strongly Agree",
          text: "Strongly Agree"
        }
      ],
      rows: [

        { value: "informationProvision1", text: "The system provided information to understand why the items were recommended." },
        { value: "informationProvision2", text: "The system provided information about how my preferences were inferred." },
        { value: "informationProvision3", text: "The system provided information about how well the recommendations match my preferences." },
        
        { value: "understandingIO1", text: "It was clear to me what kind of data the system uses to generate recommendations." },
        { value: "understandingIO2", text: "I understood what data was used by the system to infer my preferences." },
        { value: "understandingIO3", text: "I understood why the items were recommended to me." },
        { value: "understandingIO4", text: "I understood how the quality of the items was determined by the system." },
        { value: "understandingIO5", text: "I understood why the system determined that the recommended items would suit me." },

        { value: "attentionCheck3", text: "Please select \"Neutral\" here." },

        { value: "interaction1", text: "I know what actions to perform in the system so that it generates better recommendations." },
        { value: "interaction2", text: "I know what needs to be changed in order to get better recommendations." },

      ]
    },
    {
      "type": "comment",
      "name": "commentAfterMiniTasks",
      isRequired: false,
      title: "Do you have additional comments regarding your experience during these tasks or the recommender system in general? (optional)"
    }
  ]
};


class FinalRecommendationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCityIndex: 0,
      model: new Survey.Model({ elements: [] }),
      prevCityIds: []
    }
  }

  componentDidMount() {
    const { shouldResetRecommenderProgress, resetRecommenderProgress, endTimeTracking, endClickTracking, clickCountRef, highlightedCityId } = this.props;
    if (shouldResetRecommenderProgress()) {
      resetRecommenderProgress();
    } else {
      endTimeTracking();
      endClickTracking(clickCountRef);
    }
  }

  // bad hacky solution :(
  static getDerivedStateFromProps(props, state) { 
    /*
    const cityIds = props.cities.map(c => c.id);

    if (!isEqual(cityIds, state.prevCityIds)) {
      const { location } = props;
      const urlParameters = queryString.parse(location.search);
      const surveyType = urlParameters.sv;
      let questions;
      if (surveyType === 'short') {
        questions = questionsSubset;
      } else {
        questions = questionsSet;
      }
      const model = new Survey.Model(questions);
      // model.getQuestionByName("recommendation").choices = [
      //   ...props.cities.slice(0,5).map((c, i) => `${c.name}|${c.name} - recommendation #${i+1}`),
      //   'none|None of them'
      // ];

      return { model, prevCityIds: cityIds };
    }
    return null;
    */
    const model = new Survey.Model(props.whichQuestionnaire === "afterFreeTask" ? questionsSetPostTask : props.whichQuestionnaire === "afterMiniTasks" ? questionsSetPostTest : questionsSetInitial);
    return { model, prevCityIds: [props.highlightedCityId] }
  }
  
  onComplete = (survey, options) => {
    console.log("submitting survey "+this.props.whichQuestionnaire);
    const { submitSurvey } = this.props;
    submitSurvey(this.props.whichQuestionnaire, survey.data);
  }
  //onCompleteOnce = once(this.onComplete);

  render() {
    const { cities, isLoading, location, surveysSubmitted, highlightedCity, whichQuestionnaire, onNextStepClick } = this.props;

    const { selectedCityIndex, model } = this.state;
    const maxSimilarity = cities[0] ? 1/cities[0].distance : 0;

    const urlParameters = queryString.parse(location.search);
    const surveyType = urlParameters.sv;
    const isProlific = !!urlParameters.PROLIFIC_PID;

    return (
      isLoading
      ? <CenteredContainer><Spinner /></CenteredContainer>
      : (whichQuestionnaire === "afterFreeTask" && !highlightedCity) ? <p>No city selected. Did you accidentally refresh the page? Please start again from the beginning.</p> :
        <CenteredContainer style={{maxWidth: "960px", margin: "0 auto"}}>
          {whichQuestionnaire !== "afterFreeTask" ? <></> :
          <CityDetailsPane city={highlightedCity}  maxSimilarity={maxSimilarity} />
          }
          {surveyType === 'short'
            ?
              <StandardRow>{/*
                <StandardLargeContainer className="col-sm-12 col-md-12 offset-lg-1 col-lg-10">
                  <Survey.Survey
                    model={model}
                    onComplete={this.onComplete}
                    completeText="Submit answers"
                    completedHtml="<span id='title'>Thank you for the feedback!</span><span id='subtitle'>Click on 'Start over' to get change your preferences and get new recommendations.</span>"
                  />
        </StandardLargeContainer>*/}
              </StandardRow>
            :
              isProlific
                ?
                <React.Fragment>
                  {/*<Survey.Survey
                      model={model}
                      onComplete={this.onComplete}
                      completeText="Submit answers"
                      completedHtml="You are now being redirected to prolific!"
                  />
        {survey_Submitted ? window.location.href = "https://app.prolific.co/submissions/complete?cc=6D4E4B23" : true}*/}
                </React.Fragment>              
                : 
              <React.Fragment>
                <PageTitle style={{marginTop: '15px'}}>{whichQuestionnaire === "afterFreeTask" ? "Free Task Questionnaire" : whichQuestionnaire === "afterMiniTasks" ? "Final Questionnaire" : "Initial Questionnaire"}</PageTitle>
                {!surveysSubmitted[whichQuestionnaire === "afterFreeTask" ? 1 : whichQuestionnaire === "afterMiniTasks" ? 2 : 0] && 
                  <HintMessage>Please complete the survey. We greatly appreciate your help in our research. {/*Afterwards, we will provide you an option to further explore DistRec and get new recommendations, without having to fill out the survey once again.*/}</HintMessage>
                }
                <StandardRow>
                  <StandardLargeContainer className="col-sm-12 col-md-12 col-lg-12">
                    <Survey.Survey
                      model={model}
                      onComplete={this.onComplete}
                      completeText="Submit answers"
                      completedHtml={
                        whichQuestionnaire === "afterFreeTask" ? "<span id='title'>Thank you!</span><span id='subtitle'>Click below to start the next step.</span>" :
                        whichQuestionnaire === "afterMiniTasks" ? "<span id='title'>You're done! Thank you very much for taking the time to complete this survey!</span><span id='subtitle'>We truly value the information you provided us with. Your responses will contribute to our research and help us find new ways to improve interactive recommender systems! For any questions regarding this research and your participant rights, contact [ANONYMIZED]. Return to Prolific by clicking the button below.</span>" :
                        "<span id='title'>Alright, so let's get started!</span><span id='subtitle'>We will begin by letting you familiarize with our system. Click 'Start Intro' below to begin.</span>"
                      }
                    />

                    {!surveysSubmitted[whichQuestionnaire === "afterFreeTask" ? 1 : whichQuestionnaire === "afterMiniTasks" ? 2 : 0] && 
                      <SurveyFootnote>Note: questions marked with * are required!</SurveyFootnote>
                    }

                    {whichQuestionnaire !== "afterMiniTasks" || !surveysSubmitted[2] ? <></> :
                    <>
                    <div style={{textAlign: "center"}}>
                    {!!urlParameters.c ? <button onClick={() => { window.location.href = "https://app.prolific.com/submissions/complete?cc=C16WUKF0" }} style={{marginBottom: "20px"}}>RETURN TO PROLIFIC</button> : <p style={{marginBottom: "50px", color: "red"}}>You did not come from Prolific or your Prolific ID was lost somehow. If you think this is an error, contact us.</p>}
                    <p>With this study, we aim to understand the effects of slider designs with different levels of complexity. Each participant was randomly assigned to one of the three slider conditions shown below.</p>
                    <p>While the top slider only shows how many cities lie within its range, the middle slider also shows the distribution of cities. The bottom slider additionaly visualizes how well the cities match the preferences via color-coding.</p>
                    <img alt="" src={slider1} style={{display: "block", margin: "10px auto"}}/>
                    <img alt="" src={slider2} style={{display: "block", margin: "10px auto"}}/>
                    <img alt="" src={slider3} style={{display: "block", margin: "10px auto"}}/>
                    </div>
                    </>
                    }

                    {whichQuestionnaire !== "initial" || !surveysSubmitted[0] ? <></> :
                    <>
                    <div style={{textAlign: "center"}}>
                    <button onClick={onNextStepClick}>START INTRO</button>
                    </div>
                    </>
                    }

                    {whichQuestionnaire !== "afterFreeTask" || !surveysSubmitted[1] ? <></> :
                    <>
                    <div style={{textAlign: "center"}}>
                    <button onClick={onNextStepClick}>START MINI TASKS</button>
                    </div>
                    </>
                    }
                  </StandardLargeContainer>
                </StandardRow>
              </React.Fragment>              
          }
        </CenteredContainer>              
    );
  }
}

export default FinalRecommendationPage;