import React from 'react';
import styled from 'styled-components'
import { ModifiedCard } from '../styles';
import {CardHead, SerifBullet} from '../../../../CommonStyles';
import InfoBullet from '../../../../components/InfoBullet';
import CostIndicator from '../../../../components/CostIndicator';
import MatchLevelIndicator from '../../../../components/MatchLevelIndicator';
import { ProgressBarWithMargin, TemperatureField } from '../../styles';
import ReactTooltip from 'react-tooltip'
import './bounded-react-tooltip.css';

const MAP_KEY = 'nfDTGswfvhlIVG3XwaxfZenrjx4hJjUK'
const venueCountLabelFunction = val => [(Math.pow(10, val*2+2)*1.5).toFixed(0)].map(str => str.length > 3 ? str.slice(0,-3)+","+str.slice(-3) : str)[0]+" K";
const attributes = {
  "cost": ["costOfLivingIndex", "Cost of Living",      val => ((val+0.25)*20).toFixed(2)+" €"    , "Indicates how costly daily expenses are.", "For instance, a city for which this value is 10.00&nbsp;€ will be twice as expensive as a city for which this value is 5.00&nbsp;€."],
  "venueCount": ["venueCount", "Size",                 venueCountLabelFunction                   , "An approximation of the number of inhabitants.", "For instance, 150 K means about 150.000 inhabitants."],
  "temperature": ["averageTemperature", "Temperature", val => ( 26.6*val+3.4).toFixed(2)+" °C"   , "The average temperature over the year.", "Measured in °C."],
  "precipitation": ["averagePrecipitation", "Rain",    val => (266.6*val+1.6).toFixed(1)+" mm"   , "The monthly precipitation, averaged over the year.", "Measured in mm of precipitation per month."],
  "food": ["food", "Dining",                           val => (      val*4+1).toFixed(2)+" stars", "Average of user ratings on the food and dining experience.", "1.00&nbsp;stars is worst, 5.00&nbsp;stars is best."],
  "nightlife": ["nightlife", "Party",                  val => (      val*4+1).toFixed(2)+" stars", "Average of user ratings on the nightlife.", "1.00&nbsp;stars is worst, 5.00&nbsp;stars is best."],
  "arts": ["artsAndEntertainment", "Arts",             val => (      val*4+1).toFixed(2)+" stars", "Average of user ratings on arts and entertainment opportunities.", "1.00&nbsp;stars is worst, 5.00&nbsp;stars is best."],
  "outdoor": ["outdoorsAndRecreation", "Outdoor",      val => (      val*4+1).toFixed(2)+" stars", "Average of user ratings on outdoors and recreational opportunities.", "1.00&nbsp;stars is worst, 5.00&nbsp;stars is best."],
  /////"travel": ["travelAndTransport", "Travel & Transport"],
  /////"shops": ["shopsAndServices", "Shops & Services"],
}
const attributeOrder = ['cost', 'venueCount', 'temperature', 'precipitation', 'food', 'nightlife', 'arts', 'outdoor'/*, 'travel', 'shops' */];

const Container = styled(ModifiedCard)`
  min-height: 350px;
  max-width: 400px;
  margin: 0 auto;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  cursor: auto;
`;

const RichContentContainer = styled.div`
  width: 100%;
  display: inline-flex;
  flex-grow: 1;
  margin-bottom: 10px;

  > div#image {
    background-color: ${p => p.theme.lightblue};
    background-image: url(${p => p.pictureUrl});
    background-size: cover;
    background-position: center; 
    width: 30%;
    min-height: 100%;
    margin-right: 10px;
    border-radius: 2px;
  }

  > div#map {
    background-image: url(${p => p.mapUrl});
    background-color: ${p => p.theme.lightblue};
    background-size: cover;
    background-position: center; 
    width: 50%;
    min-height: 100%;
    border-radius: 2px;
    display: block;
  }

  @media (max-width: 576px) {
    > div#image {
      width: 50%;      
    }
    > div#map {
      width: 50%;      
    }
  }
`;

const DetailsContainer = styled.div`
  width: 100%;
  display: inline-flex;

  > div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > * + * {
      margin-top: 8px;
    }
  }

  > div:first-child {
    margin-right: 5px;
  }
  > div:last-child {
    margin-left: 5px;
  }
`;

const CityDetailsPane = props => {
  const { city } = props;
  const mapUrl = `https://www.mapquestapi.com/staticmap/v5/map?key=${MAP_KEY}&locations=${city.name && city.name.split(' ').join('+')},${city.country && city.country.split(' ').join('+')}|marker-sm-2E71F0&zoom=5&type=light&size=500,250@2x`
  return(<>
    <p style={{'fontSize': '20px'}}>You have selected this city:</p>
    <Container style={{minHeight: 0, maxWidth: "300px"}}>
      <CardHead>
        <div style={{textAlign: "center", margin: "0 auto"}}>
          <span id="title">{city.name}</span>
          <span id="subtitle">{city.country}</span>
        </div>
      </CardHead>

      <div style={{borderTop: "1px solid #aaa", padding: "15px 5px"}}>
      <DetailsContainer>
        <ReactTooltip html={true} id={"tipLeft"}   className="bounded-react-tooltip" place="left"    type="dark" effect="solid" />
        <div>
        {Object.entries(attributes).map(([attrKey, [feature, uiLabel, labelFunction, description, units, ...rest]], index) =>
          index > 3 ? <></> :
          <div data-for="tipLeft" data-tip={"<div style='text-align: center'><div><strong>"+description+"</strong></div><div style='margin-top: 6px; font-size: 80%;'>"+units+"</div></div>"}>
            <InfoBullet label={uiLabel}>{labelFunction(city[feature+"Scaled"])}</InfoBullet>
          </div>
        )}
        </div>
        <ReactTooltip html={true} id={"tipRight"}   className="bounded-react-tooltip" place="right"    type="dark" effect="solid" />
        <div>
        {Object.entries(attributes).map(([attrKey, [feature, uiLabel, labelFunction, description, units, ...rest]], index) =>
          index <= 3 ? <></> :
          <div data-for="tipRight" data-tip={"<div style='text-align: center'><div><strong>"+description+"</strong></div><div style='margin-top: 6px; font-size: 80%;'>"+units+"</div></div>"}>
            <div style={{textAlign: "right"}}><InfoBullet label={uiLabel}>{labelFunction(city[feature+"Scaled"])}</InfoBullet></div>
          </div>
        )}
        </div>
      </DetailsContainer>
      </div>
      {/*
      <RichContentContainer style={{'height': '100px'}} pictureUrl={city.pictureUrl} mapUrl={mapUrl}>
        <div id="map" style={{'width': '100%'}}></div>
      </RichContentContainer>

      <DetailsContainer>
        <div>
          <InfoBullet label="price level">
            <CostIndicator value={city.costOfLivingIndexBin/5} symbolCount={5} />
          </InfoBullet>
          <InfoBullet label="climate type">
            <SerifBullet>{city.climateType}</SerifBullet>
          </InfoBullet>
          <InfoBullet label="average temperature">
            <TemperatureField>
              <span>{Number(city.averageTemperature).toFixed(1)}°C</span>
              <ProgressBarWithMargin value={city.averageTemperature / 30.0} margin={0} fillColor={'#5D26CF'}/>
            </TemperatureField>
          </InfoBullet>
        </div>
        <div>
          <InfoBullet label="food">
            <MatchLevelIndicator value={city.foodRank/180} symbolCount={10}/>
          </InfoBullet>
          <InfoBullet label="arts and entertainment">
            <MatchLevelIndicator value={city.artsAndEntertainmentRank/180} symbolCount={10}/>
          </InfoBullet>
          <InfoBullet label="outdoors and recreation">
            <MatchLevelIndicator value={city.outdoorsAndRecreationRank/180} symbolCount={10}/>
          </InfoBullet>
          <InfoBullet label="nightlife">
            <MatchLevelIndicator value={city.nightlifeRank/180} symbolCount={10}/>
          </InfoBullet>
        </div>
  </DetailsContainer>*/}
    </Container></>
  );
}

export default CityDetailsPane;