import React from 'react';
import { CenteredContainer } from '../styles';
import ScoreCritiquer from './ScoreCritiquer';
import CityDetailsPane from "../CritiquingPage/CityDetailsPane";




class CritiquingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentAspectIndex: 0,
      allCities: undefined
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    const { shouldResetRecommenderProgress, resetRecommenderProgress } = this.props;
    if (shouldResetRecommenderProgress()) {
      resetRecommenderProgress();
    }
    this.scroll(this.myRef);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.scroll(this.myRef);
  }

  scroll(ref) {
    ref.current.scrollTo(0, 0);
  }

  render() {
    const { 
      startTimeTracking,
      startClickTracking,
      endTimeTracking,
      endClickTracking,
      clickCount,
      clickCountRef,

      cities, 
      statistics, 
      isLoading,
      onNextStepClick, 
      onCritiqueClick, 
      allCities, 
      highlightedCityId, 
      setHighlightedCityId, 
      version, 

      miniTask, 
      miniTasksCompleted,
      miniTaskSubstep, 
      setMiniTaskSubstep,

      lastMiniTask,
      submitMiniTaskSurvey, 

      isScaffolding,
      scaffoldingSubstep,
      setScaffoldingSubstep,
      scaffoldingConfig,

      chosenAttributeOrder,
      setChosenAttributeOrder,
      sliderStates,
      setSliderStates,
      hiddenAttrKeys,
      setHiddenAttrKeys,
    } = this.props;

    let critiques = [];

    if (typeof cities === 'undefined' || cities.length === 0){
      critiques = [{currentRecommendation: true, name: ''}];
    }
    else{
      critiques = cities;
    }
    return (
      <div ref={this.myRef} style={{height: "100%"}}>
      <CenteredContainer style={{height: "100%"}}>
          {/*
          <div style={{width: "50%", margin: "0 auto"}}>
          We have computed this recommendation based on your previous choices, but we are not entirely sure if this is actually the city you would potentially like to visit.<br />
          In this step, you can explore the aspects of several cities in comparison to your current recommendation. You can now decide whether you want to refine your recommendation to give the system more information about what kind of city you like.
          </div>
          <CityDetailsPane city={cities[0] || {}}  maxSimilarity={10} isLoading={isLoading}/>
          */}
          <div className="row" style={{height: "100%"}}>{/*row ensures (min)width of children expands outwards??*/}
            <ScoreCritiquer 
              startTimeTracking={startTimeTracking}
              startClickTracking={startClickTracking}
              endTimeTracking={endTimeTracking}
              endClickTracking={endClickTracking}
              clickCount={clickCount}
              clickCountRef={clickCountRef}

              version={version} 
              cities={critiques} 
              allCities={allCities} 
              highlightedCityId={highlightedCityId} 
              setHighlightedCityId={setHighlightedCityId} 
              statistics={statistics || {}} 
              onFinish={onNextStepClick} 
              onNextStepClick={onNextStepClick} /* important for mini tasks! */
              onCritiqueClick={onCritiqueClick} 
              isLoading={isLoading} 

              miniTask={miniTask} 
              miniTasksCompleted={miniTasksCompleted}
              miniTaskSubstep={miniTaskSubstep} 
              setMiniTaskSubstep={setMiniTaskSubstep} 

              lastMiniTask={lastMiniTask}
              submitMiniTaskSurvey={submitMiniTaskSurvey} 

              isScaffolding={isScaffolding}
              scaffoldingSubstep={scaffoldingSubstep}
              setScaffoldingSubstep={setScaffoldingSubstep}
              scaffoldingConfig={scaffoldingConfig}

              chosenAttributeOrder={chosenAttributeOrder}
              setChosenAttributeOrder={setChosenAttributeOrder}
              sliderStates={sliderStates}
              setSliderStates={setSliderStates}
              hiddenAttrKeys={hiddenAttrKeys}
              setHiddenAttrKeys={setHiddenAttrKeys}
            />
          </div>
      </CenteredContainer>
      </div>
    );
  }
}

export default CritiquingPage;