import React from 'react'
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip'
import SmallCity from "../../PreferencesPage/SmallCity";
import Spinner from "../../../../components/Spinner";
import { HintMessage, ValueLabel } from '../../styles';
import { fetchAllCities } from '../../api';

import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import './bounded-react-tooltip.css';
import './flex-fix.css';

import * as d3 from 'd3';

import { flow, filter, sortBy, map } from 'lodash/fp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEye, faEyeSlash, faUndo, faBookmark } from '@fortawesome/free-solid-svg-icons';
//console.log(faAddressBook); // [4] is path d=---

import { Virtuoso } from 'react-virtuoso';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import Modal from "react-bootstrap/Modal";
import ModalDialog from "react-bootstrap/ModalDialog";
import Draggable from "react-draggable";
import { Resizable } from "re-resizable";
import './mymodal.css';

import * as Survey from "survey-react";
import "survey-react/survey.css";
//import once from 'lodash/once';


import { attributes, attributeOrder, overallUtilityBySliderStates, multiPartition, utilityAtLeast, getItemGroups } from '../../attributes';


function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
  // todo for each word, see https://flexiple.com/javascript/javascript-capitalize-first-letter
}





// ------------------------------------------- Helper Components ------------------------------------------------

const Slider = styled.div.attrs(() => ({
  className: 'row'
}))`
  border: 2px solid #81cdfa; /*#d3d3dc;*/
  border-radius: 5px;
  margin-right: 0px !important;
  margin-left: 0px !important;
  margin-bottom: 20px;
  background-color: white;
  width: 790px; /* was 1150px, this is 10px wider than the largest media query content width but doesn't matter */
  height: 112px;

  position: relative;
`;

const StyledLabel = styled.label`
    font-size: 11.75px;
    transform: rotate(30deg);
    width: 125px;
    text-align: left;
    margin-top: 50px;
    margin-left: -10px;
`;

const StyledInput= styled.input`
    float:left;
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 5px;
`;

const FeatureLabelDiv= styled.div`
    width: 116px;
    /*padding: 17px 12px;*/
    position: relative;
`;

// const StatsDiv= styled.div`
//     margin-bottom: 10px;
//     background-color: white;
// `;

const FeatureRankDiv= styled.div`
    font-size: 15px;
    margin-top: 5px;
`;

const FeatureLabel= styled.span`
    position: absolute;
    top: -2px;
    left: -2px;
    background-color: #5187ce;
    padding: 4px 8px;
    color: white;
    border-radius: 4px;
    font-size: 11pt;
    display: inline-block;
    /*width: 100%;*/
    height: 30px;
    text-align: center;
    white-space: nowrap;
    &:hover{
      cursor: help;
    }
`;

const FeatureChip= styled(FeatureLabel)`
    margin: 0 5px;
    position: static;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
`;

const CityOption = styled.div.attrs(() => ({
    className: 'column'
  }
))`
    margin-right: -115px;
    color: #a2a5a9;
`;

const RecommendedCityOption = styled.div.attrs(() => ({
    className: 'column'
  }
))`
    margin-right: -115px;
    color: #000000;
`;

const Dot = styled.div.attrs(() => ({
    className: 'column'
  }
))`
  border-left: 2px solid #00000040;
  width: 0;
  overflow: hidden;
  height: 16px;
`; // ^ THIS IS NEW

const PointerDiv = styled.div`
  &:hover{
    cursor: pointer;
    background-color: #e4e4e4;
  }
`;

const StyledRangeSlider = styled(RangeSlider)`
  /*position: absolute;*/
  /*display: inline-block;*/
  /*left: (this.props.sliderStates[attrKey][0]*600+30)+"px", */
  /*width: ((this.props.sliderStates[attrKey][1]-this.props.sliderStates[attrKey][0])*600+2)+"px", */

  & .range-slider__range {
    background-color: #81cdfa !important; /* Why do I need !important here and below? */
    height: 48px !important;
    box-sizing: border-box;
  }
  & .range-slider__thumb {
    background-color: transparent !important;
    height: 128px !important;
    border-radius: 0px !important;
    box-sizing: border-box;
    z-index: 10 !important;
  }

  background: none !important;
  margin-top: 20px !important;
  /*borderTop: (this.state.onlyOthers[attrKey] ? "5px" : "0px") + " solid white",*/
  /*opacity: this.state.onlyOthers[attrKey] ? "0.5" : 1,*/
}} 
`;

const CustomA = styled.a`
  &:hover {
    text-decoration: none;
    background-color: #d4d4d4 !important; /* why do I need !important here? */
  }
`;

const CustomAOrSpan = ({clickDisabled, style, onClick, children}) => {
  return clickDisabled
    ? <span style={style}>
      {children}
    </span>
    : <CustomA href="#" style={style} onClick={onClick}>
      {children}
    </CustomA>
}



// Function to compute density
function kernelDensityEstimator(kernel, X) {
  return function(V) {
    return X.map(function(x) {
      return [x, d3.mean(V, function(v) { return kernel(x - v); }) || 0];
    });
  };
}
function kernelEpanechnikov(k) {
  return function(v) {
    return Math.abs(v /= k) <= 1 ? 0.75 * (1 - v * v) / k : 0;
  };
}

const Distribution = ({accentColor, cities, attrKey, chosenAttributeOrder, sliderStates, hiddenAttrKeys, onlyOthers, thresholds, opacities, dynamic}) => {
  const initialized = React.useRef(false);
  const prevData = React.useRef({});
  const x = React.useRef(undefined);
  const y = React.useRef(undefined);
  const svgRef = React.useRef(undefined);

  const width = 660;
  const height = 60;

  const d3init = () => {
    let svg = d3.select(svgRef.current);

    
    // get the data
    //d3.csv("https://raw.githubusercontent.com/holtzy/data_to_viz/master/Example_dataset/1_OneNum.csv").then(data => { /* ... */ });


    thresholds.forEach((threshold, i) => {

      const opacity = opacities[i];

      // add the x Axis
      x.current = d3.scaleLinear()
                .domain([-0.05, 1.05])
                .range([0, width]);
      svg.append("g")
          .attr("transform", "translate(0," + height + ")")
          .call(d3.axisBottom(x.current));

      // add the y Axis
      y.current = d3.scaleLinear()
                .range([height, 0])
                .domain([0, 3.9 /**180/data.length*/ ]);
      /*
      svg.append("g")
          .call(d3.axisLeft(y));
      */

      // Plot the area
      svg.append("path")
          .attr("class", "mypath-"+attrKey+"-"+i);
        
        
      });

      d3update();
    }


  const d3update = () => {
    let svg = d3.select(svgRef.current);
    

    thresholds.forEach((threshold, i) => {

      const opacity = opacities[i];

      const filteredCities = threshold === undefined ? cities : cities.filter(c => overallUtilityBySliderStates(c, sliderStates, chosenAttributeOrder.filter(otherAttrKey => !hiddenAttrKeys.includes(otherAttrKey) && (onlyOthers ? otherAttrKey !== attrKey : true))) >= threshold);

      //if (prevData.current && i in prevData.current && prevData.current[i] === data) {
      //  return;
      //}

      if (filteredCities) {
        //prevData.current[i] = data;

        y.current.domain([0, 3.9 *180/filteredCities.length ]);
        
        const data = filteredCities.map( c => c[ attributes[attrKey][0]+"Scaled" ] );

        // Compute kernel density estimation
        var kde = kernelDensityEstimator(kernelEpanechnikov(0.02), x.current.ticks(1000))
        const density = kde( data );
        
        svg.select(".mypath-"+attrKey+"-"+i)
            .datum(density)
            .attr("fill", accentColor)
            .attr("opacity", opacity)
            .attr("stroke", accentColor)
            .attr("stroke-width", 1)
            .attr("stroke-linejoin", "round")
            .attr("d",  d3.line()
              .curve(d3.curveBasis)
                .x(function(d) { return x.current(d[0]); })
                .y(function(d) { return y.current(d[1]); })
            );
      }

    });
  } 

  const d3initOrUpdate = () => {
    if (svgRef && svgRef.current) {
      if (svgRef.current.hasChildNodes()) {
        d3update();
        return;
      }
      if (!initialized.current){
        d3init();
        initialized.current=true; // only needed to call d3initOrUpdate only on first render via useEffect
      }
    }

  }

  React.useEffect(() =>{ if (!initialized.current) { console.log("REDRAW"); d3initOrUpdate(attrKey)}});
  React.useMemo(() => d3initOrUpdate(attrKey), dynamic ? [cities, chosenAttributeOrder, sliderStates, hiddenAttrKeys, onlyOthers] : [cities, chosenAttributeOrder, onlyOthers]);

  
  return (
    <div style={{display: "inline-block"}}>
      <svg width={width} height={height} ref={svgRef} />
    </div>
  );
};

const MiniButton = ({position, icon, onClick, tooltip, id}) => {
  return (
    <div>
    <ReactTooltip html={true} id={id} className="bounded-react-tooltip helper-tooltip" place="top" type="light" effect="solid" delayShow={600} border={true} />
    <PointerDiv 
      style={{
        position: "absolute", 
        outline: "0", 
        borderRadius: "3px", 
        zIndex: 11, 
        height: "18px", 
        width: "24px", 
        padding: "5px 2px", 
        textAlign: "center",
        boxSizing: "content-box", // is this the default?
        ...position,
      }}
      data-for={id}
      data-tip={tooltip}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} style={{verticalAlign: "top", maxHeight: "100%", maxWidth: "100%"}} />
    </PointerDiv>
    </div>
  );
}

const Landmark = ({featureValueScaled, labelFunction, accentColor, position, showLine, sidePadding, zIndex}) => {
  let backgroundStyles = showLine ? { backgroundColor: accentColor } : {};
  let top = position === "top" ? -8 : position === "classical-top" ? 24 : position === "bottom" ? 100 : 75;
  return (
    <div style={{zIndex: zIndex, position: "absolute", top: top+"px", left: (featureValueScaled*600+30-1)+"px", width: "2px", height: (60-top)+"px", ...backgroundStyles}}>
      <div style={{width: "120px", marginLeft: "-60px", height: "18px"}}>
        <p style={{display: "inline-block", verticalAlign: "top", whiteSpace: "nowrap", padding: "0 "+sidePadding, fontSize: "8pt", border: "1px solid "+accentColor, color: accentColor, backgroundColor: "white", borderRadius: "3px",
                  }}>{labelFunction(featureValueScaled)}</p>
      </div>
    </div>
  );
}

const LandmarkBackground = ({featureValueScaledLeft, featureValueScaledRight, labelFunction, backgroundColor, accentColor, zIndexForLabel, rightAligned}) => {
  const alignStyle = rightAligned ? { right: 0 } : { left: 0 };
  return (
    <div style={{position: "absolute", top: "60px", left: (featureValueScaledLeft*600+30-1)+"px", width: ((featureValueScaledRight-featureValueScaledLeft)*600+2)+"px", height: "48px", backgroundColor: backgroundColor}}>
        <p style={{display: "inline-block", verticalAlign: "top", whiteSpace: "nowrap", fontSize: "8pt", color: accentColor, fontWeight: "bold", minWidth: ((featureValueScaledRight-featureValueScaledLeft)*600+2)+"px", padding: "0 5px", height: "18px", textAlign: "center",
                  zIndex: zIndexForLabel, position: "absolute", ...alignStyle
                  }}>{labelFunction(featureValueScaledLeft, featureValueScaledRight)}</p>
    </div>
  );
}




// =========================================================================================================
// ------------------------------------------- Modal Dialog ------------------------------------------------

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable handle=".modal-title">
        <ModalDialog {...this.props} />
      </Draggable>
    );
  }
}





// ===========================================================================================================
// ------------------------------------------- Main Component ------------------------------------------------



const miniTaskQuestionsSets = [
  {
    elements: [
      /*
      {
        type: "matrix",
        name: "evaluation",
        title: "* What do you believe will happen? State your level of agreement to the following statements.",
        isAllRowRequired: true,
        columns: [
          {
            value: "Strongly Disagree",
            text: "Strongly Disagree"
          },
          {
            value: "Disagree",
            text: "Disagree"
          },
          {
            value: "Neutral",
            text: "Neutral"
          },
          {
            value: "Agree",
            text: "Agree"
          },
          {
            value: "Strongly Agree",
            text: "Strongly Agree"
          }
        ],
        rows: [
          {
            value: "a0",
            text: "I am confident that the adjustment will strongly reduce the number of perfectly matching recommendations."
          },
          {
            value: "a1",
            text: "I am confident that the number of perfectly matching recommendations will not be reduced much by the adjustment."
          },
          { 
            value: "a2",
            text: "I cannot say at all how the number of perfectly matching recommendations will change after adjusting the slider."
          },
          {
            value: "a3",
            text: "I am confident that some perfectly matching recommendations will show after adjusting the slider."
          },
          {
            value: "a4",
            text: "I am confident that some well matching recommendations will show after adjusting the slider."
          },
          {
            value: "a5",
            text: "I am confident that only pooly matching recommendations will show after adjusting the slider."
          },
          { 
            value: "a6",
            text: "I cannot say at all how well the shown recommendations will match after adjusting the slider."
          },
        ],
      },
      */
      {
        type: "matrix",
        name: "expectancyBest",
        title: "* Please indicate what you expect to happen.",
        isAllRowRequired: true,
        columns: [
          {
            value: "Perfectly Matching",
            text: "Perfectly Matching"
          },
          {
            value: "Well Matching",
            text: "Well Matching"
          },
          {
            value: "Poorly Matching",
            text: "Poorly Matching"
          },
          {
            value: "Don't Know",
            text: "Don't Know"
          },
        ],
        rows: [
          {
            value: "expectancyBest1",
            text: "When now adjusting the slider as described above, I expect that the best recommendations will be shown as ..."
          },
        ],
      },
      {
        type: "matrix",
        name: "expectancyNumber",
        title: "* Please indicate what you expect to happen.",
        isAllRowRequired: true,
        columns: [
          {
            value: "Greatly Reduced",
            text: "Greatly Reduced"
          },
          {
            value: "Only Slightly Reduced",
            text: "Only Slightly Reduced"
          },
          {
            value: "Don't Know",
            text: "Don't Know"
          },
        ],
        rows: [
          {
            value: "expectancyNumber1",
            text: "When now adjusting the slider as described above, I expect that the number of perfectly matching recommendations will be ..."
          },
        ],
      },
    ],
  },
  {
    elements: [
      {
        type: "matrix",
        name: "realityBest",
        title: "* Please indicate what you are seeing right now.",
        isAllRowRequired: true,
        columns: [
          {
            value: "Perfectly Matching",
            text: "Perfectly Matching"
          },
          {
            value: "Well Matching",
            text: "Well Matching"
          },
          {
            value: "Pooly Matching",
            text: "Pooly Matching"
          },
        ],
        rows: [
          {
            value: "realityBest1",
            text: "Accoring to the system, the best recommendations shown are ..."
          },
        ],
      },
      {
        type: "matrix",
        name: "realityNumber",
        title: "* Please indicate what you are seeing right now.",
        isAllRowRequired: true,
        columns: [
          {
            value: "Greatly Reduced",
            text: "Greatly Reduced"
          },
          {
            value: "Only Slightly Reduced",
            text: "Only Slightly Reduced"
          },
          {
            value: "Don't Know",
            text: "Don't Know"
          },
        ],
        rows: [
          {
            value: "realityNumber1",
            text: "The number of perfectly matching recommendations I can still see has been ..."
          },
        ],
      },
      {
        type: "matrix",
        name: "difficultyForMiniTask",
        title: "* Please indicate how difficult it was to predict what would happen.",
        isAllRowRequired: true,
        columns: [
          {
            value: "1",
            text: "1 (Very Difficult)"
          },
          {
            value: "2",
            text: "2"
          },
          {
            value: "3",
            text: "3"
          },
          {
            value: "4",
            text: "4"
          },
          {
            value: "5",
            text: "5"
          },
          {
            value: "6",
            text: "6"
          },
          {
            value: "7",
            text: "7 (Very Easy)"
          }
        ],
        rows: [
          { value: "difficulty1", text: "Overall, this task was ..." },
        ],
      },
        /*
      {
        type: "matrix",
        name: "evaluation",
        title: "* Please indicate your level of agreement with the following statements.",
        isAllRowRequired: true,
        columns: [
          {
            value: "Strongly Disagree",
            text: "Strongly Disagree"
          },
          {
            value: "Disagree",
            text: "Disagree"
          },
          {
            value: "Neutral",
            text: "Neutral"
          },
          {
            value: "Agree",
            text: "Agree"
          },
          {
            value: "Strongly Agree",
            text: "Strongly Agree"
          }
        ],
        rows: [
          {
            value: "b2",
            text: "I had expected that the currently best recommendations would be this good/bad."
          },
          {
            value: "b3",
            text: "It surprised me that the best recommendations I currently see are this good/bad."
          },
        ],
      },
      */
      {
        "type": "comment",
        "name": "commentForMiniTask",
        isRequired: false,
        title: "Do you have additional comments? (optional)"
      }
    ],
  },
];
const miniTaskSurveyModels = miniTaskQuestionsSets.map(questionsSet => new Survey.Model(questionsSet));

const RidgePlotSlider = ({
  attrKey,
  feature,
  uiLabel,
  labelFunction,
  description,
  units,

  allCities,
  version,
  chosenAttributeOrder,
  onlyOthers,
  setOnlyOthers,
  reconciliationMode,

  sliderStates,
  setSliderStates,
  hiddenAttrKeys,
  setHiddenAttrKeys,
  highlightedCityId,
  setHighlightedCityId,
  bookmarkedCityIds,
  setBookmarkedCityIds,

  miniTask,
  miniTaskSubstep,
  isScaffolding,

  removeFromChosenAttributes,
  resetSliderState,
  toggleHidden,
  hidden,
}) => {

  return ( 
    <Slider key={attrKey} style={hidden(attrKey) ? {borderColor: "#ddd"} : {}}>
      {miniTask !== undefined || isScaffolding ? <></> : <>
      <MiniButton position={{top: 0     , right: 0     }} icon={faTimes}                              onClick={removeFromChosenAttributes} id={"remove" +attrKey} tooltip="Remove attribute" />
      <MiniButton position={{top: "52px", left : "20px"}} icon={faUndo}                               onClick={resetSliderState}           id={"reset"  +attrKey} tooltip="Reset slider range" />
      <MiniButton position={{top: "52px", left : "65px"}} icon={hidden(attrKey) ? faEyeSlash : faEye} onClick={toggleHidden}               id={"disable"+attrKey} tooltip="<div>Disable / enable slider</div><div style='font-size: 80%; margin-top: 3px'>Ranges of disabled sliders are not considered for recommendations.</div>" />
      </>}

      <FeatureLabelDiv style={{display: "inline-block"}}>
        <ReactTooltip html={true} id="tipRight" className="bounded-react-tooltip" place="right" type="dark" effect="solid"/>
        <FeatureLabel data-for="tipRight" data-tip={"<div><strong>"+description+"</strong></div><div style='margin-top: 6px; font-size: 80%;'>"+units+"</div>"} style={hidden(attrKey) ? {backgroundColor: "#bbb"} : {}}>{uiLabel}</FeatureLabel>
      </FeatureLabelDiv>
      <div style={{position: "relative", display: "flex", flexDirection: "column", marginTop: version === "64f91682" ? "-30px" : "0" }}>
        <LandmarkBackground
          backgroundColor={"#ddd"}
          accentColor={"#5187ce"}
          featureValueScaledLeft={ sliderStates[attrKey][0]} 
          featureValueScaledRight={sliderStates[attrKey][1]} 
          labelFunction={() => hiddenAttrKeys.includes(attrKey) ? "" : "#"+allCities.filter(c => c[ attributes[attrKey][0]+"Scaled" ] >= sliderStates[attrKey][0] && c[ attributes[attrKey][0]+"Scaled" ] <= sliderStates[attrKey][1]).length} 
          zIndexForLabel={5}
          rightAligned={false}
        />
        <LandmarkBackground
          backgroundColor={"transparent"}
          accentColor={"#5187cea0"}
          featureValueScaledLeft={ 0} 
          featureValueScaledRight={sliderStates[attrKey][0]} 
          labelFunction={() => hiddenAttrKeys.includes(attrKey) ? "" : "#"+allCities.filter(c => c[ attributes[attrKey][0]+"Scaled" ] < sliderStates[attrKey][0]).length} 
          zIndexForLabel={5}
          rightAligned={true}
        />
        <LandmarkBackground
          backgroundColor={"transparent"}
          accentColor={"#5187cea0"}
          featureValueScaledLeft={ sliderStates[attrKey][1]} 
          featureValueScaledRight={1} 
          labelFunction={() => hiddenAttrKeys.includes(attrKey) ? "" : "#"+allCities.filter(c => c[ attributes[attrKey][0]+"Scaled" ] > sliderStates[attrKey][1]).length} 
          zIndexForLabel={5}
          rightAligned={false}
        />
        {hidden(attrKey) ? <></> : <>
        <Landmark
          accentColor={"#5187ce"} 
          featureValueScaled={ sliderStates[attrKey][0]} 
          labelFunction={labelFunction} 
          position={"bottom"} 
          showLine={false} 
          sidePadding={"4px"}
          zIndex={2}
        />
        <Landmark
          accentColor={"#5187ce"} 
          featureValueScaled={ sliderStates[attrKey][1]} 
          labelFunction={labelFunction} 
          position={"bottom"} 
          showLine={false} 
          sidePadding={"4px"}
          zIndex={2}
        />
        </>}
        {!reconciliationMode ? <></> : bookmarkedCityIds.map(bookmarkedCityId =>
        <>
        <Landmark 
          accentColor={"brown"} 
          featureValueScaled={ allCities.filter(c => c.id === bookmarkedCityId)[0][ attributes[attrKey][0]+"Scaled" ]} 
          labelFunction={() => allCities.filter(c => c.id === bookmarkedCityId)[0].name} 
          position={version === "64f91682" ? "classical-top" : "top"} 
          showLine={true} 
          sidePadding={"10px"}
          zIndex={3}
        />
        <Landmark
          accentColor={"brown"} 
          featureValueScaled={ allCities.filter(c => c.id === bookmarkedCityId)[0][ attributes[attrKey][0]+"Scaled" ]} 
          labelFunction={labelFunction} 
          position={"middle"} 
          showLine={false} 
          sidePadding={"4px"}
          zIndex={3}
          />
        </>
        )}
        {highlightedCityId === undefined ? <></> :
        <>
        <Landmark 
          accentColor={"#62BE6A"} 
          featureValueScaled={ allCities.filter(c => c.id === highlightedCityId)[0][ attributes[attrKey][0]+"Scaled" ]} 
          labelFunction={() => allCities.filter(c => c.id === highlightedCityId)[0].name} 
          position={version === "64f91682" ? "classical-top" : "top"} 
          showLine={true} 
          sidePadding={"10px"}
          zIndex={4}
        />
        <Landmark
          accentColor={"#62BE6A"} 
          featureValueScaled={ allCities.filter(c => c.id === highlightedCityId)[0][ attributes[attrKey][0]+"Scaled" ]} 
          labelFunction={labelFunction}
          position={"middle"} 
          showLine={false} 
          sidePadding={"4px"}
          zIndex={4}
        />
        </>
        }
        {version === "64f91682"
        ? <>
          {/*<div style={{position: "absolute", height: "60px", width: "660px", borderBottom: "1px solid #666"}}>
            <div style={{height: (60-1)+"px", margin: "0 30px", backgroundImage: "radial-gradient(circle at 300px 80px, #ffffdd 10%, #ffffff 50%)"}} />
          </div>*/}
          </>
        :
        <div style={{position: "absolute"}}>
          <Distribution accentColor={version === "9f2c3dc5" ? "#666" : "darkgreen"} cities={allCities} attrKey={attrKey} chosenAttributeOrder={chosenAttributeOrder} sliderStates={sliderStates} hiddenAttrKeys={hiddenAttrKeys} onlyOthers={onlyOthers[attrKey]} thresholds={[undefined]} opacities={[version === "9f2c3dc5" ? 1.0 : 0.2]} dynamic={false} />
        </div>}
      <Distribution accentColor={"darkgreen"} cities={allCities} attrKey={attrKey} chosenAttributeOrder={chosenAttributeOrder} sliderStates={sliderStates} hiddenAttrKeys={hiddenAttrKeys} onlyOthers={onlyOthers[attrKey]} thresholds={version !== "4cc20c83" ? [] : [0.8, 1.000]} opacities={version !== "4cc20c83" ? [] : [0.4, 1.0]} dynamic={version !== "4cc20c83" ? false : true} />
      <span style={{position: "relative", width: "661px", height: "8px"}}>
        <div style={{position: "absolute", width: (600+24)+"px", left: (30-12)+"px", height: "8px"}}>
          <div style={{position: "absolute", width: "2px", height: "12px", backgroundColor: "#ddd", left: "12px", top: (24-6)+"px"}} />
          <div style={{position: "absolute", width: "2px", height: "12px", backgroundColor: "#ddd", right: "12px", top: (24-6)+"px"}} />
          <div style={{position: "absolute", width: "600px", height: "2px", backgroundColor: "#ddd", left: "12px", top: (24-1)+"px"}} />
        {hidden(attrKey) ? <>{/*LandmarkBackground from above becomes visible here*/}</> :
        <StyledRangeSlider 
          min={0} 
          max={1} 
          step={0.01} 
          rangeSlideDisabled={miniTask === undefined ? false : true} 
          thumbsDisabled={
            miniTask === undefined ? [false, false] : 
            miniTaskSubstep >= miniTask.promptQuestionPairs.length ? [true, true] :
            [!miniTask.promptQuestionPairs[miniTaskSubstep][2].includes(attrKey), !miniTask.promptQuestionPairs[miniTaskSubstep][3].includes(attrKey)] }
          value={sliderStates[attrKey]} 
          onInput={newSliderState => {
            const newSliderStates = {...sliderStates}; newSliderStates[attrKey] = newSliderState; setSliderStates(newSliderStates); 
          }}
          //onClick={e => {
          //  if (e.detail === 2) {
          //    const newOnlyOthers = {...onlyOthers}; newOnlyOthers[attrKey] = !newOnlyOthers[attrKey]; setOnlyOthers(newOnlyOthers); 
          //  }
          //}}
        />
        }
        </div>
      </span>
      {/*<span style={{position: "relative", display: "inline-block", width: "661px"}}>
        {rows.map(function (i) {
          return placeHolderGetters[attrKey](i);
        })}
      </span>*/}
      </div>
      
    </Slider>
  );
}



// ----------------------- ScoreCritiquer -----------------------------

const initialState = {
  toggledAccordionEntryIndex: 0,

  shouldFlash: true,
  critiquingCycle: 0,

  bookmarkedCityIds: [],

  onlyOthers: {},

  reconciliationMode: false,
  showModal: false,
  miniTaskSurveyModels,
};

class ScoreCritiquer extends React.Component {




  // --------------- Init Stuff ---------------

  constructor(props) {
    super(props);
    this.state = initialState;
    this.virtuosoRef = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {

    if (props.miniTask !== undefined && props.miniTask !== state.miniTask) {
      // TODO Scroll virtuoso to top -> NOW FIXED in componentDidUpdate()

      const {/*chosenAttributeOrder, sliderStates,*/ taskDescription} = props.miniTask;
      

      return {
        ...initialState, 
        
        version: props.version,
        statistics: props.statistics || {}, 
        
        itemGroups: getItemGroups(props.allCities, props.miniTask.sliderStates, props.miniTask.chosenAttributeOrder, []),
        //itemGroups: [props.allCities, [], []], //TODO hacky, setting item groups like this will make them not update based on initialSliderStates -> NOW FIXED
        miniTask: props.miniTask,

        //chosenAttributeOrder,
        //sliderStates,
        taskDescription,
      };
    }

    const itemGroups = getItemGroups(props.allCities, props.sliderStates, props.chosenAttributeOrder, props.hiddenAttrKeys);
    /*flow(
      map(c => { return { city: c, name: c.name, country: c.country, invertedUtility: 1.0-overallUtilityBySliderStates(c, props.sliderStates, props.chosenAttributeOrder.filter(otherAttrKey => !props.hiddenAttrKeys.includes(otherAttrKey))) }; }),
      sortBy(['invertedUtility','name']),
      multiPartition([utilityAtLeast(1.0), utilityAtLeast(0.8)])
    )(props.allCities);*/

    return {
      version: props.version, // || ["9f2c3dc5", "4cc20c83", "64f91682"][Math.floor(Math.random()*3)],    
      statistics: props.statistics || {}, 

      itemGroups
    };
  }

  componentDidMount() {
    const { startTimeTracking, startClickTracking, clickCountRef } = this.props;
    console.log("startTimeTracking");
    startTimeTracking();  
    console.log("startClickTracking");
    startClickTracking(clickCountRef);  
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.miniTask !== undefined && this.state.miniTask !== prevState.miniTask) {
      this.virtuosoRef.current.scrollToIndex(0);
    }
  }




  // --------------- Attribute Setters ---------------

  setSelected = attrKey => i => {
    let attrVal = attributes[attrKey];
    if (attrVal) {
      let feature = attributes[attrKey][0];
      let refVal = feature + i;
      ReactDOM.findDOMNode(this.refs[refVal]).checked = true;
    }
    else {
      console.log("attrVal is "+attrVal+" for attrKey="+attrKey+", i="+i);
    }
  }
  
  

  // --------------- Helper Functions for Update Handlers ---------------

  updateStateAndSelectOtherFeatures = (critiquedCity, feature) => {
    let recommendedCity = this.props.cities.find(({currentRecommendation}) => currentRecommendation === true);
    this.setState({critiquedCity: critiquedCity});
    this.setState({recommendedCity: recommendedCity});
    this.setState({critiquedFeature: feature});

    this.props.chosenAttributeOrder.forEach(attrKey => { const [feature, ...rest] = attributes[attrKey];
      console.log("before setSelected("+attrKey+")");
      this.setSelected(attrKey)(critiquedCity[feature+"Rank"])
      console.log("after setSelected("+attrKey+")");
    });
  };

  

  // --------------- Update Handlers ---------------

  handleClick = (clickedFeature) => (i, feature) => {
    const { cities } = this.props;

    let critiquedCity = cities.find(c => c[clickedFeature+"Rank"] === i);
    this.updateStateAndSelectOtherFeatures(critiquedCity, feature);
  }
  
  

  // --------------- Style-related Functions ---------------

  getStyle = (i, feature) => {
    let recommendedCity = this.state.recommendedCity;
    let critiquedCity = this.state.critiquedCity;

    if (critiquedCity === null){
      return {"backgroundColor": "transparent"};
    }

    if (i < recommendedCity[feature] && i > critiquedCity[feature]) {
      return {"backgroundColor": "#fab1a0", "height": "13px"};
    }

    if (i < critiquedCity[feature] && i > recommendedCity[feature]) {
      return {"backgroundColor": "#88D68F", "height": "13px"};
    }

    return {"backgroundColor": "transparent"};
  }


  getStyleLabel = (i, feature) => {
    let critiquedCity = this.state.critiquedCity;

    if (critiquedCity === null){
      return {"color": "#a2a5a9"};
    }

    if (i === critiquedCity[feature]) {
      return {"color": "#6B7476"};
    }

    return {"color": "#a2a5a9"};
  };

  

  // --------------- Advance to Next Step ---------------

  onContinueClick = () => {
    if (this.state.recommendedCity.id === this.state.critiquedCity.id) {
      this.props.onFinish();
    }
    else {
      this.props.onCritiqueClick([this.state.critiquedCity.id],[this.state.critiquedFeature]);
    }
  };



  // --------------- Mini Button functions ------------------

  removeFromChosenAttributes = (attrKey) => () => {
    const newChosenAttributeOrder = [...this.props.chosenAttributeOrder].filter(remainingAttrKey => remainingAttrKey !== attrKey);
  
    const newSliderStates = {...this.props.sliderStates};
    delete newSliderStates[attrKey];

    const newHiddenAttrKeys = [...this.props.hiddenAttrKeys].filter(remainingHiddenAttrKey => remainingHiddenAttrKey !== attrKey);
  
    this.props.setChosenAttributeOrder(newChosenAttributeOrder);
    this.props.setSliderStates(newSliderStates);
    this.props.setHiddenAttrKeys(newHiddenAttrKeys);
  }

  toggleHidden = (attrKey) => () => {
    if (this.props.hiddenAttrKeys.includes(attrKey)) {
      const newHiddenAttrKeys = [...this.props.hiddenAttrKeys].filter(remainingHiddenAttrKey => remainingHiddenAttrKey !== attrKey);
      this.props.setHiddenAttrKeys(newHiddenAttrKeys);
    }
    else {
      const newHiddenAttrKeys = [...this.props.hiddenAttrKeys, attrKey];
      this.props.setHiddenAttrKeys(newHiddenAttrKeys);
    }
  }

  resetSliderState = (attrKey) => () => {
    const newSliderStates = {...this.props.sliderStates};
    newSliderStates[attrKey] = [0,1];
    this.props.setSliderStates(newSliderStates);
  }







  onComplete = (survey, options) => {
    const { miniTasksCompleted, miniTaskSubstep, setMiniTaskSubstep, submitMiniTaskSurvey } = this.props;
    submitMiniTaskSurvey(miniTasksCompleted, miniTaskSubstep, survey.data);
    survey.clear(); // necessary?
    this.setState({ showModal: !this.state.showModal }); 
    setMiniTaskSubstep(miniTaskSubstep + 1);
  }
  // onCompleteOnce = once(this.onComplete);

  
  

  // =====================================================
  // --------------- Render Main Component ---------------

  render() {
    const { cities, isLoading } = this.props;
    let recommendedCity = this.props.cities.find(({currentRecommendation}) => currentRecommendation === true);
    let critiquedCity = this.state.critiquedCity;
    let iteration = this.state.critiquingCycle;
    let allCities = this.props.allCities;



    // ---- create render objects ----

    let ranks = {};
    let ranksRec = {};
    this.props.chosenAttributeOrder.forEach(attrKey => { const [feature, ...rest] = attributes[attrKey];
      ranks[attrKey] = allCities.map(a => {
        let rObj = {};
        rObj.id = a[feature+"Rank"];
        rObj.name = a.name;
        rObj.left = a[feature+"Scaled"];
        return rObj;
      });
      ranksRec[attrKey] = cities.map(c => c[feature+"Rank"]);
    });


    // ---- getPlaceHolder functions ----

    const PlaceHolder = ({label, handler, currentRecRank, recRank, allRank, i}) => {
      let cityForIndex = allRank.find(({id}) => id === i); // should now be defined for all indices
      if (!cityForIndex) { 
        //console.log("no city for index "+i+" ("+label+")"); 
        return <div key={i} />;
      }
      // const left = i;                     // rank-based  [0 ; 179]
      const left = cityForIndex.left*180; // value-based [0 ; 180]

      /*
      if (currentRecRank === i) {
        return (
          <RecommendedCityOption key={i} index={i} id={label+i} style={{...this.getStyle(i, label+"Rank"), position: "absolute", left: (left*900/180).toFixed(2) + "px", zIndex: 20}}>
            <StyledInput type="radio" name={label} ref={label+i} defaultChecked={recommendedCity.id === critiquedCity.id} onClick={() => handler(i, label)}/>
            <StyledLabel>{recommendedCity.name}</StyledLabel>
          </RecommendedCityOption>
        );
      }
      */

      let recommended = recRank.find(id => id === i);
      if (false) { // (recommended){
        return <CityOption key={i} index={i} id={label+i} style={{...this.getStyle(i, label+"Rank"), position: "absolute", left: (left*900/180).toFixed(2) + "px", zIndex: 20}}>
        <StyledInput type="radio" name={label} ref={label+i} onClick={() => handler(i, label)}/><StyledLabel style={this.getStyleLabel(i, label+"Rank")}>{cityForIndex.name}</StyledLabel></CityOption>;
      }
      else{
        return <Dot key={i} index={i} ref={label+i} style={{...this.getStyle(i, label+"Rank"), position: "absolute", left: (left*600/180+30).toFixed(2) + "px", opacity: 0.5}}></Dot>;
      }
    };
    

    const getPlaceHolderGetter = (label, handler, currentRecRank, recRank, allRank) => (i) => 
      PlaceHolder({label, handler, currentRecRank, recRank, allRank, i})
      //<PlaceHolder label={label} handler={handler} currentRecRank={currentRecRank} recRank={recRank} allRank={allRank} i={i} key={i} />
    ;
    const placeHolderGetters = {};
    this.props.chosenAttributeOrder.forEach(attrKey => { const [feature, ...rest] = attributes[attrKey];
      placeHolderGetters[attrKey] = getPlaceHolderGetter(feature, this.handleClick(feature), recommendedCity[feature+"Rank"], ranksRec[attrKey], ranks[attrKey]);
    });

    

    // ---- logic for task-related buttons and tooltips ----
    /*
    let buttonText = "Confirm";
    let toolTipText = "This was our initial recommendation for you. You can select `" + recommendedCity.name + "`" +
      " below again";
    let differentCitiesSelected = false;
    if (this.state.recommendedCity.id !== this.state.critiquedCity.id){
      buttonText = "Continue with";
      differentCitiesSelected = true;
    }
    let sufficientCritiquingsteps = iteration >= 2;

    if (!sufficientCritiquingsteps){
      toolTipText = recommendedCity.name + " is our current recommendation for you. You should refine this recommendation a few times before you submit.";
    } else {
      toolTipText = "This was our initial recommendation for you. You can select `" + recommendedCity.name + "`" +
      " below again";
    }

    var rows = [], i = 0, len = 180;

    while (++i <= len) rows.push(i);
    */



    // ---- intuitive state ----

    const used = (attrKey) => this.props.chosenAttributeOrder.includes(attrKey);
    const hidden = (attrKey) => this.props.hiddenAttrKeys.includes(attrKey);



    // ---- return actual render ----

    return (
      isLoading ? <Spinner/> :

      <div style={{'margin': '0 auto', height: "100%"}} ref="main">
        {this.state.miniTask === undefined ? <></> :
        <Modal
          show={this.state.showModal}
          onHide={() => {} /* prevent clicking outside the modal from having any effect */}
          dialogAs={DraggableModalDialog}
        >
          <Resizable className="modal-resizable" defaultSize={{ width: "860px", height: "400px"}} minWidth="250px" maxWidth="1000px" minHeight="300px" maxHeight="600px">
          <Modal.Header /*closeButton*/>
            <Modal.Title>Please answer the questions.</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{fontSize: "66.67%", margin: "1em 1.5em"}}>You may still look at the interface. You can resize and move this window by dragging it if it is in the way.</p>
            <p style={{color: "black", backgroundColor: "#FFFF80", padding: "10px", margin: "0 1em"}}>{this.props.miniTaskSubstep >= this.state.miniTask.promptQuestionPairs.length ? "" : this.props.miniTask.promptQuestionPairs[this.props.miniTaskSubstep][1]}</p>
            <div style={{minWidth: "720px"}}>
            <Survey.Survey
              model={this.state.miniTaskSurveyModels[this.props.miniTaskSubstep]}
              onComplete={this.onComplete}
              completeText="Submit answers"
              /*completedHtml="<span id='title'>Thank you for the feedback!</span><span id='subtitle'>Click on 'Start over' to get change your preferences and get new recommendations.</span>"*/
            />  
            </div>
          </Modal.Body>
          {/*<Modal.Footer><button onClick={() => {}}>SEND</button></Modal.Footer>*/}
          </Resizable>
        </Modal>
        }


        {/* Task information */}
            {/*
        <div className="column" style={{'position': 'sticky', 'top': '0', 'height': '125px', 'backgroundColor': '#f2f3f7', 'margin': 'auto 0', 'zIndex': '10'}}>
          <div className="row" style={{'display': 'inline-block', border: "2px solid #999", padding: "20px", maxWidth: "900px"}}>
            <strong>{this.props.taskDescription}</strong>
              differentCitiesSelected ?
                <div>
                  <ReactTooltip place="top" type="dark" effect="solid"/>
                  <span data-tip={toolTipText}><button style={{'fontWeight': '600', 'borderRadius': '0px', 'backgroundColor': 'rgb(250, 177, 160)', 'marginRight': '5px'}} disabled={true}>{recommendedCity.name}</button></span>
                  <button style={{'backgroundColor': '#474bde', 'color': 'white', 'fontWeight': '600', 'borderRadius': '5px'}} onClick={() => this.onContinueClick()}>{buttonText} {critiquedCity.name}</button>
                </div>
                :
                sufficientCritiquingsteps ?
                  <div>
                     Please continue refining your recommendations below or <button style={{'backgroundColor': 'rgb(136, 214, 143)', 'color': 'black', 'fontWeight': '600', 'borderRadius': '5px'}} onClick={() => this.onContinueClick()}>{buttonText} {critiquedCity.name} as final recommendation</button>
                  </div>
                :
                <div>
                  <ReactTooltip place="top" type="dark" effect="solid"/>
                  <span data-tip={toolTipText}><button style={{'fontWeight': '600', 'borderRadius': '0px', 'backgroundColor': 'lightgray',  'marginRight': '5px'}} disabled={true}>{recommendedCity.name}</button></span>
                </div>
          </div>
        </div>
            */}
        {/*
        <HintMessage>
          {
          //Please explore the cities below and continue with the city you find most appealing based on its attributes, even if it might not be feasible for you to travel to any time soon!<br />
          //Cities which are placed more to the right have a higher value in the respective feature.
          }
          Please look at the following attribute sliders and explore the item space.
          <br /><br /><br />
        </HintMessage>
        */}
          
        <div style={{display: "flex", height: "100%", boxSizing: "border-box"}}>

          {/* Main Content */}
          <div style={{width: "830px", marginRight: "10px", overflowY: "auto"}}>
          <div style={{height: "100%", display: "flex", flexDirection: "column"}}>

            {this.state.miniTask !== undefined ? 
            /* MINI TASK PAGE: Show Mini Task Steps */
            <>
              {this.state.miniTask.promptQuestionPairs.slice(0, this.props.miniTaskSubstep + 1).map(([prompt, question,,,buttonEnableConditions, ...rest], index) => 
              <>
              <p style={{width: "790px", border: "2px solid #6B7476", borderRadius: "10px", padding: "6px 15px", textAlign: "left"}}>
              {"("+ (index + 1) + ") " + prompt}
              </p>
              {index !== this.props.miniTaskSubstep ? <></> :
              <div style={{textAlign: "center", marginBottom: "20px"}}>
                <button style={{width: "150px", lineHeight: 1.3}} disabled={! buttonEnableConditions.every(([theAttrKey, theLeft, theRight]) => this.props.sliderStates[theAttrKey][0]>=theLeft && this.props.sliderStates[theAttrKey][1]<=theRight)} onClick={() => { this.props.endTimeTracking(); this.props.endClickTracking(this.props.clickCountRef); this.setState({showModal: true}); }}>{"STEP (" + (index + 1) + ") " + "DONE"}</button>
                <span style={{fontSize: "70%", marginLeft: "15px"}}>A pop-up window with a few questions will open.</span>
              </div>
              }
              </>
              )}
              
              {this.props.miniTaskSubstep < this.props.miniTask.promptQuestionPairs.length ? <></> :
              <>
              <p style={{width: "790px", border: "2px solid #6B7476", borderRadius: "10px", padding: "6px 15px", textAlign: "left"}}>
              {"Thank you! Click 'Next' to continue to the " + (this.props.lastMiniTask ? "final questionnaire." : "next task.")}
              </p>
              <div style={{textAlign: "center", marginBottom: "20px"}}>
                <button style={{width: "150px", lineHeight: 1.3}} onClick={() => { if (!this.props.lastMiniTask) { this.props.startTimeTracking(); this.props.startClickTracking(this.props.clickCountRef); } this.props.onNextStepClick(); }}>NEXT</button>
              </div>
              </>
              }
            </>
            :
            this.props.isScaffolding === true ?
            /* SCAFFOLDING PAGE: Introduction */
            <>
            {this.props.scaffoldingConfig.slice(0, this.props.scaffoldingSubstep + 1).map((s, index) => 
            <p style={{width: "790px", border: "2px solid #6B7476", borderRadius: "10px", padding: "6px 15px", textAlign: "left"}}>
              {s[0]}
            </p>
            )}
            {this.props.scaffoldingSubstep >= this.props.scaffoldingConfig.length - 1 ? <></> :
              <div style={{textAlign: "center", marginBottom: "20px"}}>
                <button style={{width: "150px", lineHeight: 1.3}} onClick={
                  this.props.scaffoldingSubstep < this.props.scaffoldingConfig.length - 1 /*here we do not need an 'extra' last substep as with mini-tasks*/ 
                  ? (() => {
                      if (this.props.scaffoldingSubstep === 2) { // next step will be 4th step with index 3
                        const newSliderStates = { ...this.props.sliderStates, "temperature": [0,1] };
                        const newOnlyOthers = { ...this.state.onlyOthers, "temperature": false };
                        const newChosenAttributeOrder = [...this.props.chosenAttributeOrder, "temperature"];
                        this.props.setSliderStates(newSliderStates);
                        this.props.setChosenAttributeOrder(newChosenAttributeOrder);
                        this.setState({ onlyOthers: newOnlyOthers }); 
                      }
                      if (this.props.scaffoldingSubstep === this.props.scaffoldingConfig.length - 2) { // next step will be last step with index length - 1
                        const newSliderStates = { ...this.props.sliderStates, "venueCount": [0,1] };
                        const newOnlyOthers = { ...this.state.onlyOthers, "venueCount": false };
                        const newChosenAttributeOrder = [...this.props.chosenAttributeOrder, "venueCount"];
                        this.props.setSliderStates(newSliderStates);
                        this.props.setChosenAttributeOrder(newChosenAttributeOrder);
                        this.setState({ onlyOthers: newOnlyOthers }); 
                      }
                      this.props.setScaffoldingSubstep(this.props.scaffoldingSubstep + 1);
                    }) 
                  : this.props.onNextStepClick
                }>
                  NEXT
                </button>
              </div>
            }
            {/*<RidgePlotSlider 

              attrKey={"temperature"}
              feature={attributes["temperature"][0]}
              uiLabel={attributes["temperature"][1]}
              labelFunction={attributes["temperature"][2]}
              description={attributes["temperature"][3]}
              units={attributes["temperature"][4]}
            
              allCities={this.props.allCities}
              version={this.state.version}
              chosenAttributeOrder={this.props.chosenAttributeOrder}
              onlyOthers={this.state.onlyOthers}
              setOnlyOthers={onlyOthers => this.setState({onlyOthers})}
              reconciliationMode={this.state.reconciliationMode}
            
              sliderStates={this.state.scaffoldingSliderStates} // !
              setSliderStates={scaffoldingSliderStates => this.setState({scaffoldingSliderStates})} // !
              hiddenAttrKeys={this.state.scaffoldingHiddenAttrKeys} // !
              setHiddenAttrKeys={scaffoldingHiddenAttrKeys => this.setState({scaffoldingHiddenAttrKeys})} // !
              highlightedCityId={this.props.highlightedCityId}
              setHighlightedCityId={this.props.setHighlightedCityId}
              bookmarkedCityIds={this.state.bookmarkedCityIds}
              setBookmarkedCityIds={bookmarkedCityIds => this.setState({bookmarkedCityIds})}
            
              miniTask={this.state.miniTask}
              miniTaskSubstep={this.props.miniTaskSubstep}
              isScaffolding={this.props.isScaffolding}
            
              removeFromChosenAttributes={this.removeFromChosenAttributes(attrKey)}
              resetSliderState={this.resetSliderState(attrKey)}
              toggleHidden={this.toggleHidden(attrKey)}
              hidden={hidden}
            />*/}
            </>
            :
            /* REGULAR CRITIQUING PAGE: Feature Chips */
            <>
            <p style={{width: "790px", border: "2px solid #6B7476", borderRadius: "10px", padding: "6px 15px", textAlign: "left"}}>
              Start by picking from the following city attributes those that you consider most relevant. You can also pick and remove attributes later at any time as you like.
            </p>
            <div style={{marginBottom: "20px", minHeight: "30px", width: "790px"}}>
            {
              attributeOrder.filter(attrKey => !used(attrKey)).map(attrKey => { const [feature, uiLabel, , description, ...rest] = attributes[attrKey];
                return(<React.Fragment key={attrKey}>
                  <ReactTooltip html={true} id={"tipBottom"+attrKey}   className="bounded-react-tooltip" place="bottom"    type="dark" effect="solid" />
                  <span data-for={"tipBottom"+attrKey} data-tip={"<div><strong>"+description+"</strong></div>"}>
                  <FeatureChip 
                    key={attrKey}
                    
                    
                    onClick={() => { 
                      ReactTooltip.hide();
                      const newSliderStates = { ...this.props.sliderStates, [attrKey]: [0,1] };
                      const newOnlyOthers = { ...this.state.onlyOthers, [attrKey]: false };
                      const newChosenAttributeOrder = [...this.props.chosenAttributeOrder, attrKey];
                      this.props.setSliderStates(newSliderStates);
                      this.props.setChosenAttributeOrder(newChosenAttributeOrder);
                      this.setState({ onlyOthers: newOnlyOthers }); 
                    }}
                  >
                    {uiLabel}
                  </FeatureChip></span>
                  </React.Fragment>);
              })
            }
            {attributeOrder.filter(attrKey => !used(attrKey)).length > 0 ? <></> :
              <HintMessage>You have already picked all features.</HintMessage>
            }
            </div>
            {this.props.chosenAttributeOrder.length === 0 ? <></> : 
            <p style={{width: "790px", border: "2px solid #6B7476", borderRadius: "10px", padding: "6px 15px", textAlign: "left"}}>
              Use the sliders below to set your preferences. You can adjust them at any time. 
            </p>
            }
            </>}



            {/* Sliders */}
            <div style={{/*overflowY: "auto",*/ flexGrow: 1, paddingTop: "10px"}}>
            {
              this.props.chosenAttributeOrder.map(attrKey => { const [feature, uiLabel, labelFunction, description, units, ...rest] = attributes[attrKey];
                return (
                  <RidgePlotSlider 
                    key={attrKey} 

                    attrKey={attrKey}
                    feature={feature}
                    uiLabel={uiLabel}
                    labelFunction={labelFunction}
                    description={description}
                    units={units}
                  
                    allCities={this.props.allCities}
                    version={this.state.version}
                    chosenAttributeOrder={this.props.chosenAttributeOrder}
                    onlyOthers={this.state.onlyOthers}
                    setOnlyOthers={onlyOthers => this.setState({onlyOthers})}
                    reconciliationMode={this.state.reconciliationMode}
                  
                    sliderStates={this.props.sliderStates}
                    setSliderStates={this.props.setSliderStates}
                    hiddenAttrKeys={this.props.hiddenAttrKeys}
                    setHiddenAttrKeys={this.props.setHiddenAttrKeys}
                    highlightedCityId={this.props.highlightedCityId}
                    setHighlightedCityId={this.props.setHighlightedCityId}
                    bookmarkedCityIds={this.state.bookmarkedCityIds}
                    setBookmarkedCityIds={bookmarkedCityIds => this.setState({bookmarkedCityIds})}
                  
                    miniTask={this.state.miniTask}
                    miniTaskSubstep={this.props.miniTaskSubstep}
                    isScaffolding={this.props.isScaffolding}
                  
                    removeFromChosenAttributes={this.removeFromChosenAttributes(attrKey)}
                    resetSliderState={this.resetSliderState(attrKey)}
                    toggleHidden={this.toggleHidden(attrKey)}
                    hidden={hidden}
                  />
                );
              })
            }
            </div>
            
          </div></div>
 


          {/*Sidebar*/}
          <div style={{display: "flex", flexDirection: "column", width: "350px", height: "100%"}}>

            {/*Recommendations and Bookmarks Switch*/}
            {/*<p>
              <CustomAOrSpan clickDisabled={!this.state.reconciliationMode} style={{display: "inline-block", padding: "3px 6px", borderRadius: "4px", fontWeight: "bold", backgroundColor: !this.state.reconciliationMode ? "darkgreen" : "transparent", color: !this.state.reconciliationMode ? "white" : "darkgreen"}} onClick={() => {this.setState({reconciliationMode: false})}}>
                Recommendations
              </CustomAOrSpan>
              <CustomAOrSpan clickDisabled={this.state.reconciliationMode} style={{display: "inline-block", padding: "3px", borderRadius: "4px", fontWeight: "bold", backgroundColor: this.state.reconciliationMode ? "brown" : "transparent", color: this.state.reconciliationMode ? "white" : "brown", marginLeft: "10px"}} onClick={() => {
                if (this.state.bookmarkedCityIds.includes(this.props.highlightedCityId)) { 
                  this.setState({reconciliationMode: true}); 
                } 
                else { 
                  this.setState({reconciliationMode: true, highlightedCityId: undefined});
                }
              }}>
                <FontAwesomeIcon icon={faBookmark} fixedWidth size="lg" />
                Bookmarks
              </CustomAOrSpan>
            </p>*/}
            <p>

              {this.state.itemGroups.map((itemGroup, index) => 
              <span key={index}>
                <ReactTooltip html={true} id={"classification-tooltip"+index} className="bounded-react-tooltip" place="top" type="dark" effect="solid" />
                <span data-for={"classification-tooltip"+index} data-tip={itemGroup.length + (itemGroup.length === 1 ? " city matches" : " cities match") + (index===0 ? " your preferences perfectly" : index===1 ? " your preferences quite well" : " your preferences poorly")}>
                <CustomAOrSpan 
                  clickDisabled={itemGroup.length === 0} style={{display: "inline-block", marginLeft: "20px", padding: "5px 10px", borderRadius: "4px", color: "#6B7476"}} 
                  onClick={() => this.virtuosoRef.current.scrollToIndex({ align: "start", index:
                    this.state.itemGroups.slice(0, index).map(itemGroup => itemGroup.length).reduce((acc, cur) => acc + cur, 0)
                  })}
                >
                  <span style={{display: "inline-block", height: "20px", width: "20px", borderRadius: "20px", backgroundColor: `rgba(0, 100, 0, ${[1.0,0.4,0.2][index]})`, verticalAlign: "top"}} />
                  <span style={{display: "inline-block", width: "35px", textAlign: "right", verticalAlign: "top"}}>{itemGroup.length}</span>
                </CustomAOrSpan>
                </span>
              </span>
              )}
            </p>

            {/*Recommendation and Bookmarks Card*/}
            {!this.state.reconciliationMode ? 

            /*Recommendation Card*/
            <Card style={{boxSizing: "border-box", flex: 1}}>
            <Card.Header style={{padding: 0, height: "34px"}}>
              <div 
                style={{
                  display: "flex", 
                  justifyContent: "space-between", 
                  textAlign: "left", 
                  width:"100%", 
                  height:"100%", 
                  padding: "5px 10px", 
                  backgroundColor: "rgb(81, 135, 206)", 
                  color: "white", 
                  border: "none", 
                  outline: "none"
                }}
              >
                <div style={{fontWeight: "bold"}}>
                  {"YOUR RECOMMENDATIONS"}
                </div>
                {/*<div style={{fontWeight: 100}}>
                  {180}
                </div>*/}
              </div>
            </Card.Header>
            <Card.Body style={{padding: "0 0 0 10px", backgroundColor: "#f9f9f9"}}>
          <Virtuoso
          className="my-virtuoso"
          ref={this.virtuosoRef}
          totalCount={180}
                  itemContent={index =>
                    flow(
                      map(c => { return { city: c, name: c.name, country: c.country, invertedUtility: 1.0-overallUtilityBySliderStates(c, this.props.sliderStates, this.props.chosenAttributeOrder.filter(otherAttrKey => !hidden(otherAttrKey))) }; }),
                      sortBy(['invertedUtility','name']),
                      (citiesWithInvertedUtilitiesArray) => 
                          {
                            if (!citiesWithInvertedUtilitiesArray || !citiesWithInvertedUtilitiesArray[index]) { return <></> }
                            let {city, invertedUtility} = citiesWithInvertedUtilitiesArray[index];
                            return (
                              <>
                              { index === this.state.itemGroups[0].length + this.state.itemGroups[1].length ? 
                                  <p style={{textAlign: "left", margin: "20px 0 10px"}}>
                                    <span style={{display: "inline-block", height: "20px", width: "20px", borderRadius: "20px", backgroundColor: `rgba(0, 100, 0, 0.2)`, verticalAlign: "top", marginRight: "10px"}} />
                                    POORLY MATCHING ({this.state.itemGroups[2].length})
                                  </p>
                              : index === this.state.itemGroups[0].length ? 
                                  <p style={{textAlign: "left", margin: "20px 0 10px"}}>
                                    <span style={{display: "inline-block", height: "20px", width: "20px", borderRadius: "20px", backgroundColor: `rgba(0, 100, 0, 0.4)`, verticalAlign: "top", marginRight: "10px"}} />
                                    WELL MATCHING ({this.state.itemGroups[1].length})
                                  </p> 
                              : index === 0 ? 
                                  <p style={{textAlign: "left", margin: "20px 0 10px"}}>
                                    <span style={{display: "inline-block", height: "20px", width: "20px", borderRadius: "20px", backgroundColor: `rgba(0, 100, 0, 1.0)`, verticalAlign: "top", marginRight: "10px"}} />
                                    PERFECTLY MATCHING ({this.state.itemGroups[0].length})
                                  </p> 
                              : <></>
                              }
                            <SmallCity 
                              key={index} 
                              city={city} 
                              utility={1.0-invertedUtility}
                              onClick={() =>  { this.props.setHighlightedCityId(this.props.highlightedCityId === city.id ? undefined : city.id); } }
                              onBookmarkClick={() => {
                                if (this.state.bookmarkedCityIds.includes(city.id)) {
                                  const newBookmarkedCityIds = this.state.bookmarkedCityIds.filter(remainingId => remainingId !== city.id);
                                  this.setState({bookmarkedCityIds: newBookmarkedCityIds});
                                }
                                else {
                                  const newBookmarkedCityIds = [...this.state.bookmarkedCityIds, city.id];
                                  this.setState({bookmarkedCityIds: newBookmarkedCityIds});
                                }
                              }} 
                              highlightedCityId={this.props.highlightedCityId} 
                              bookmarkedCityIds={this.state.bookmarkedCityIds} 
                              opacity={
                                1.0-invertedUtility >= 1.000 ? 1.0 :
                                1.0-invertedUtility >= 0.8   ? 0.4 : 0.2
                              }
                              className={
                                1.0-invertedUtility >= 1.000 ? "high" :
                                1.0-invertedUtility >= 0.8   ? "medium" : "low"
                              }
                            
                            />
                            </>);
                          }
                    )(this.props.allCities)
                  }
                />
                  </Card.Body>
                  </Card>
          :
          /*Bookmarks Card*/
          <Card style={{boxSizing: "border-box", flex: 1}}>
                <Card.Header style={{padding: 0, height: "34px"}}>
                  <div 
                    style={{
                      display: "flex", 
                      justifyContent: "space-between", 
                      textAlign: "left", 
                      width:"100%", 
                      height:"100%", 
                      padding: "5px 10px", 
                      backgroundColor: "brown", 
                      color: "white", 
                      border: "none", 
                      outline: "none"
                    }}
                  >
                    <div style={{fontWeight: "bold"}}>
                    {"YOUR BOOKMARKS"}
                     </div>
                     <div style={{fontWeight: 100}}>
                      {this.state.bookmarkedCityIds.length}
                     </div>
                  </div>
                </Card.Header>
                <Card.Body style={{padding: "0 0 0 10px", backgroundColor: "#f9f9f9"}}>
              <Virtuoso
              totalCount={this.state.bookmarkedCityIds.length}
              itemContent={index => 
            flow(
              filter(c => this.state.bookmarkedCityIds.includes(c.id)),
              map(c => { return { city: c, name: c.name, country: c.country, invertedUtility: 1.0-overallUtilityBySliderStates(c, this.props.sliderStates, this.props.chosenAttributeOrder.filter(otherAttrKey => !hidden(otherAttrKey))) }; }),
              sortBy(['invertedUtility','name']),
              array => {
                const {city, invertedUtility} = array[index];
                return (
                  <div>
                <SmallCity 
                  key={index} 
                  city={city} 
                  utility={1.0-invertedUtility}
                  onClick={() => this.props.setHighlightedCityId(this.props.highlightedCityId === city.id ? undefined : city.id)} 
                  onBookmarkClick={() => {
                    if (this.state.bookmarkedCityIds.includes(city.id)) {
                      const newBookmarkedCityIds = this.state.bookmarkedCityIds.filter(remainingId => remainingId !== city.id);
                      this.setState({bookmarkedCityIds: newBookmarkedCityIds});
                    }
                    else {
                      const newBookmarkedCityIds = [...this.state.bookmarkedCityIds, city.id];
                      this.setState({bookmarkedCityIds: newBookmarkedCityIds});
                    }
                  }} 
                  highlightedCityId={this.props.highlightedCityId} 
                  bookmarkedCityIds={this.state.bookmarkedCityIds} 
                  opacity={
                    1.0-invertedUtility >= 1.000 ? 1.0 :
                    1.0-invertedUtility >= 0.8   ? 0.4 : 0.2
                  }
                
                
                />
                </div>);
              }
            
          )(this.props.allCities)
        }
              />
              </Card.Body>
              </Card>
        }



          {/*Current Selection Card*/}
          <Card style={{boxSizing: "border-box", margin: "20px 0", flexGrow: 0, flexShrink: 0}}>
                <Card.Header style={{padding: 0, height: "34px"}}>
                  <div 
                    style={{
                      display: "flex", 
                      justifyContent: "space-between", 
                      textAlign: "left", 
                      width:"100%", 
                      height:"100%", 
                      padding: "5px 10px", 
                      backgroundColor: "#77C57E", // darkened version of  "#88D68F", 
                      color: "white", 
                      border: "none", 
                      outline: "none"
                    }}
                  >
                    <div style={{fontWeight: "bold"}}>
                    {"YOUR SELECTION"}
                     </div>
                     {/*<div style={{fontWeight: 100}}>
                      {this.state.bookmarkedCityIds.length}
                  </div>*/}
                  </div>
                </Card.Header>
                <Card.Body style={{padding: "0 0 0 10px", backgroundColor: "#f9f9f9", height: "90px"}}>
                  {/*this.props.highlightedCityId === undefined ? <p style={{paddingTop: "30px"}}>No city selected.</p> : <></>*/}
                  {flow(
                    map(c => { return { city: c, name: c.name, country: c.country, invertedUtility: 1.0-overallUtilityBySliderStates(c, this.props.sliderStates, this.props.chosenAttributeOrder.filter(otherAttrKey => !hidden(otherAttrKey))) }; }),
                    array => array.map(({city, invertedUtility}) => {
                      return <SmallCity 
                        city={city} 
                        utility={1.0-invertedUtility}
                        onClick={() => this.props.setHighlightedCityId(this.props.highlightedCityId === city.id ? undefined : city.id)} 
                        onBookmarkClick={() => {
                          if (this.state.bookmarkedCityIds.includes(city.id)) {
                            const newBookmarkedCityIds = this.state.bookmarkedCityIds.filter(remainingId => remainingId !== city.id);
                            this.setState({bookmarkedCityIds: newBookmarkedCityIds});
                          }
                          else {
                            const newBookmarkedCityIds = [...this.state.bookmarkedCityIds, city.id];
                            this.setState({bookmarkedCityIds: newBookmarkedCityIds});
                          }
                        }} 
                        highlightedCityId={this.props.highlightedCityId} 
                        bookmarkedCityIds={this.state.bookmarkedCityIds} 
                        opacity={
                          1.0-invertedUtility >= 1.000 ? 1.0 :
                          1.0-invertedUtility >= 0.8   ? 0.4 : 0.2
                        }
                      />
                    })
                  )(this.props.allCities.filter(c => this.props.highlightedCityId === c.id))}
                </Card.Body>
              </Card>
          </div>{/*Sidebar*/}
        </div>{/* flexbox div */}


        {/* StatsDivs */}
        {/*<StatsDiv>{JSON.stringify(this.state.statistics.food, null, 2)}</StatsDiv>*/}
        {/*<StatsDiv>{JSON.stringify(this.state.statistics.arts, null, 2)}</StatsDiv>*/}
        {/*<StatsDiv>{JSON.stringify(this.state.statistics.outdoor, null, 2)}</StatsDiv>*/}
        {/*<StatsDiv>{JSON.stringify(this.state.statistics.travel, null, 2)}</StatsDiv>*/}
        {/*<StatsDiv>{JSON.stringify(this.state.statistics.temperature, null, 2)}</StatsDiv>*/}
        {/*<StatsDiv>{JSON.stringify(this.state.statistics.cost, null, 2)}</StatsDiv>*/}
        {/*<StatsDiv>{JSON.stringify(this.state.statistics.nightlife, null, 2)}</StatsDiv>*/}
        {/*<StatsDiv>{JSON.stringify(this.state.statistics.shops, null, 2)}</StatsDiv>*/}
        {/*<StatsDiv>{"Number of Iterations "}{JSON.stringify(this.state.statistics.noOfIterations)}</StatsDiv>*/}
        {/*<StatsDiv>{"allCities "}{JSON.stringify(this.props.allCities.map((city, index) => [index, city.name]))}</StatsDiv>*/}
      


      </div>/* centering div */
    );
  }
}

export default ScoreCritiquer;