import React from 'react';
import styled from 'styled-components';
//import logo from './logo.svg';
//import logoInverted from './logo_inverted.svg';
import distrecIcon from './distrec-icon.png';

const Container = styled.div.attrs({
  className: 'container-fluid'
})`
  padding: 15px;
`;

/*const Logo = styled.img.attrs(({ inverted }) => ({
  src: inverted ? logoInverted : logo
}))`
  margin: auto;
  display: block;
  cursor: pointer;
`;*/

const Header = props => {
  const { inverted, history, location } = props;

  const onLogoClick = () => {
    if (location.pathname.startsWith('/itupferl')) {
      history.push(`/itupferl/${location.search}`);
    } else {
      history.push(`/${location.search}`);
    }
  }

  return (
    <Container style={{height: "80px", flexGrow: 0, flexShrink: 0}}>
      {/*<Logo inverted={inverted} onClick={onLogoClick} />*/}
      
      <h1 style={{fontSize: "28pt", fontWeight: "bold", fontFamily: "Montserrat, sans-serif", color: inverted ? "white" : "#444", textAlign: "center"}}><img src={distrecIcon} style={{height: "48px", width: "48px", verticalAlign: "bottom"}} /> DistRec</h1>
    </Container>
  );
}

export default Header;