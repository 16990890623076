import React from 'react'
import styled from 'styled-components';
import { PageTitle, PageSubtitle } from '../../CommonStyles';
import { NextButton, Icon } from '../Recommender/Stepper/styles';
import skyline from './skyline.jpg';
import queryString from 'query-string';
import Checkbox from '../../components/Checkbox';





const Wrapper = styled.div`
  text-align: center;
`;

const Container = styled.div.attrs({
  className: 'col-xs-12 col-sm-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3'
})``;

const WhitePageTitle = styled(PageTitle)`
  color: white;
`;
const WhitePageSubtitle = styled(PageSubtitle)`
  color: white;
`;

const Card = styled.div`
  background-color: white;
  box-shadow: 0 5px 11px 0 #4040C6;
  border-radius: 10px;
  padding: 35px 35px 20px;
  text-align: left;
  color: ${p => p.theme.primaryText};
  margin: 20px 80px 10px;
  @media (max-width: 1300px) {
    margin: 20px 0 10px;
  }

  > span + span {
    margin-top: 20px;
  }
  > span {
    font-size: 14px;
    line-height: 26px;
    display: block;
    z-index: 1;
  }
  > span#head {
    font-weight: 700;
    font-size: 17px;
  }

  & a {
    color: ${p => p.theme.primary};
    transition: 100ms all ease-in-out;
    font-weight: 700;

    &:hover {
      color: ${p => p.theme.secondary};
    }
  }

  background-image: url(${skyline});
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size: 100%;
`;


const StartButton = styled(NextButton).attrs({
  enabled: true
})`
  background: ${p => p.theme.primary};
  color: white;
  font-size: 13px;
  padding: 16px 72px;
  border-radius: 100px;
  margin-top: 40px;
  z-index: 200 !important;
  display: block;

  & ${Icon} {
    background-color: white;;
  }

  :hover {
    background: ${p => p.theme.secondary};
  }
`;

const Footprint = styled.span`
  display: block;
  font-size: 11px;
  color: #FFFFFF99;
  padding-bottom: 10px;
`;

const ContactDetails = styled.span`
  display: block;
  font-size: 12px;
  color: #FFFFFFD9;

  & a {
    text-decoration: underline;
    color: #FFFFFF;
    transition: 200ms all ease-in-out;

    &:hover {
      color: #c3d6ff;
    }
  }

  > span + span {
    margin-left: 10px;
  }
`;

const AgreementField = styled.div`
  margin: 15px 0 10px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  text-align: center;

  > span {
    font-size: 13px;
    margin-left: 10px;
    font-weight: 700;
  }
`;

const Warning = styled.div`
  background: ${p => p.theme.red}4D;
  color: ${p => p.theme.darkRed};
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  padding: 3px 7px;
  width: fit-content;
  height: 24px;
  margin-bottom: -24px;
`;

const ModifiedCheckbox = styled(Checkbox)`
  ${AgreementField}:hover & {
    background-color: ${p => p.isChecked ? p.theme.checkbox.hover.checked.background : p.theme.checkbox.hover.unchecked.background};
    border: 3px solid ${p => p.isChecked ? p.theme.checkbox.hover.checked.border : p.theme.checkbox.hover.unchecked.border};
  }
`;

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agreement: false,
      startActionAttempted: false
    };
  }

  onStartClick = () => {
    const { agreement } = this.state;
    if (!agreement) {
      this.setState({ startActionAttempted: true });
      return;
    }

    const { match, history, location } = this.props;
    const urlParameters = queryString.parse(location.search);
    let version;
    let taskOrder;
    let newLocationSearch;
    if ('v' in urlParameters) {
      version = urlParameters['v'];
      newLocationSearch = location.search;
      //history.push(`${route}${location.search}`) 
    } else {
      let versionArray = ["9f2c3dc5","4cc20c83","64f91682"];
      version = versionArray[Math.floor(Math.random() * versionArray.length)];
      const versionParameter = location.search === '' ? `?v=${version}` : `&v=${version}`
      newLocationSearch = `${location.search}${versionParameter}`;
    }
    if ('t' in urlParameters) {
      taskOrder = urlParameters['t'];
      newLocationSearch = newLocationSearch;
      //history.push(`${route}${location.search}`) 
    } else {
      const taskOrderArray = ["ad6283", "28bf47"]; // taskOrderIDs ["ab", "ba"]
      taskOrder = taskOrderArray[Math.floor(Math.random() * taskOrderArray.length)];
      const taskOrderParameter = `&t=${taskOrder}`
      newLocationSearch = `${newLocationSearch}${taskOrderParameter}`;
    }
    const route = match.path === '/' ? `/rec/final-recommendation0` : `${match.path}/rec/final-recommendation0`;
    history.push(`${route}${newLocationSearch}`) 
  }

  onAgreementClick = () => {
    this.setState({ agreement: !this.state.agreement });
  }

  render() {
    const { agreement, startActionAttempted } = this.state;
    return(
      <Wrapper style={{overflowY: "auto"}}>
        <WhitePageTitle>A prototype recommender system</WhitePageTitle>
        <WhitePageSubtitle>User study</WhitePageSubtitle>
        <Container style={{maxWidth: "900px", margin: "0 auto", width: "900px"}}>
          <Card style={{margin: "20px 0 10px"}}>

            <span id="head">Dear participant,</span>
            <span>
            thank you for taking part in our study on interactive recommender systems, part of research at [ANONYMIZED]. 
</span><span>
Please be sure to participate in your study from a desktop computer with a maximized browser window. 
</span><span>
You can participate in this study if you are at least 18 years old. 
The study will take up about 15 minutes.
We kindly ask you to participate until the very end as we can only evaluate complete data.
If you arrived here via Prolific, you will receive compensation for full participation as indicated on the Prolific page.
</span><span>
The aim of this research is to investigate whether and how slider design can help users understand interactive recommender systems.
Note that the tasks in this study can vary by difficulty and might cause frustration during system use.
Study participation is voluntary 
and you are free to end your participation at any time without reason and without negative consequences. 
</span><span>
Important information regarding data use and data safety: The legal basis for collecting any of your personal data in user studies is your voluntary consent according to Article 6(1)(a) GDPR. 
We will only collect data you provide to us explicitly by filling out questionnaires, as well as interaction data on how you use the system. 
The only data admitting conclusions on your identity is used to handle your Prolific compensation and will be deleted immediately after it is no longer necessary. 
The remaining data is saved in anonymized form and thus admits no conclusions on your identity; 
this data is saved for at least 10 years after data collection or 10 years after publication of a scientific article on this study.
</span><span>
For any questions regarding this research and your participant rights, contact [ANONYMIZED].
</span>
            <AgreementField onClick={this.onAgreementClick}>
              <ModifiedCheckbox isChecked={agreement} />
              <span>I confirm that I am at least 18 years old. I consent to participating in the study and to my personal data being anonymously collected as described above.</span>
            </AgreementField>
            {!agreement && startActionAttempted &&
              <Warning>You must first agree to the statement above!</Warning>
            }
            <StartButton onClick={this.onStartClick}>
              <span>Start</span>
              <Icon />
            </StartButton>
          </Card>
          <ContactDetails>            
            {/*<span>Corresponding author: <a href="mailto:linus.dietz@tum.de">Linus Dietz</a></span>*/}
            <span>Corresponding author: [ANONYMIZED]</span>
          </ContactDetails>
          <Footprint>[ANONYMIZED]{/*Duisburg, Germany, 2024.*/}{/*<br /><br />Based on a system by Linus Dietz, TU Munich, Germany, 2020.*/}</Footprint>
        </Container>
      </Wrapper>
    );
  };
}

export default Landing;