import { flow, map, sortBy, partition } from 'lodash/fp';
import { curry } from 'lodash';


// ------------------------ attributes ------------------------

// for UI stuff we use shortened attribute names ("attrKeys") compared to the city object or user model properties (which we call "features")
//
// keys: shortened attribute names ("attrKeys")
// values[0]: city object or user model properties (which we call "features")
//
//
// precipitation: per Month, averaged
// cost: was 5€ in Kairo, sind 10€ in Prag und 21€ in NYC
const venueCountLabelFunction = val => [(Math.pow(10, val*2+2)*1.5).toFixed(0)].map(str => str.length > 3 ? str.slice(0,-3)+","+str.slice(-3) : str)[0]+" K";
export const attributes = {
  "cost": ["costOfLivingIndex", "Cost of Living",      val => ((val+0.25)*20).toFixed(2)+" €"    , "Indicates how costly daily expenses are.", "For instance, a city for which this value is 10.00&nbsp;€ will be twice as expensive as a city for which this value is 5.00&nbsp;€."],
  "venueCount": ["venueCount", "Size",                 venueCountLabelFunction                   , "An approximation of the number of inhabitants.", "For instance, 150 K means about 150.000 inhabitants."],
  "temperature": ["averageTemperature", "Temperature", val => ( 26.6*val+3.4).toFixed(2)+" °C"   , "The average temperature over the year.", "Measured in °C."],
  "precipitation": ["averagePrecipitation", "Precipitation",    val => (266.6*val+1.6).toFixed(1)+" mm"   , "The monthly precipitation (rain), averaged over the year.", "Measured in mm of precipitation per month."],
  "food": ["food", "Dining",                           val => (      val*4+1).toFixed(2)+" stars", "Average of user ratings on the food and dining experience.", "1.00&nbsp;stars is worst, 5.00&nbsp;stars is best."],
  "nightlife": ["nightlife", "Party",                  val => (      val*4+1).toFixed(2)+" stars", "Average of user ratings on the nightlife.", "1.00&nbsp;stars is worst, 5.00&nbsp;stars is best."],
  "arts": ["artsAndEntertainment", "Arts",             val => (      val*4+1).toFixed(2)+" stars", "Average of user ratings on arts and entertainment opportunities.", "1.00&nbsp;stars is worst, 5.00&nbsp;stars is best."],
  "outdoor": ["outdoorsAndRecreation", "Outdoor",      val => (      val*4+1).toFixed(2)+" stars", "Average of user ratings on outdoors and recreational opportunities.", "1.00&nbsp;stars is worst, 5.00&nbsp;stars is best."],
  /////"travel": ["travelAndTransport", "Travel & Transport"],
  /////"shops": ["shopsAndServices", "Shops & Services"],
}
export const attributeOrder = ['cost', 'venueCount', 'temperature', 'precipitation', 'food', 'nightlife', 'arts', 'outdoor'/*, 'travel', 'shops' */];

const featureUtilityBySliderState = (c, attrKey, [sliderStateLeft, sliderStateRight]) => {
  const value = c[ attributes[attrKey][0]+"Scaled" ]
  const sliderStateWidth = sliderStateRight - sliderStateLeft;
  let utility = 1.0;
  if (value > sliderStateRight) {
    utility = Math.max(1.0 - (value - sliderStateRight) / (sliderStateWidth / 2), 0.0);
  }
  else if (value < sliderStateLeft) {
    utility = Math.max(1.0 - (sliderStateLeft - value) / (sliderStateWidth / 2), 0.0);
  }
  return utility;
}
const featureImportanceScoreBySliderState = (c, attrKey, [sliderStateLeft, sliderStateRight]) => {
  const sliderStateWidth = sliderStateRight - sliderStateLeft;
  let importanceScore = 1.0 - sliderStateWidth;
  return importanceScore;
}
export const overallUtilityBySliderStates = (c, sliderStates, attrKeys=this.props.chosenAttributeOrder) => {
  const featureUtilities = attrKeys.map(attrKey => featureUtilityBySliderState(c, attrKey, sliderStates[attrKey]))
  const featureImportanceScores = attrKeys.map(attrKey => featureImportanceScoreBySliderState(c, attrKey, sliderStates[attrKey]))
  const weightedFeatureUtilities = attrKeys.map((attrKey, i) => featureUtilities[i] * featureImportanceScores[i]);
  const weightedFeatureUtilitiesSum = weightedFeatureUtilities.reduce((sumSoFar, val) => sumSoFar + val, 0);
  const maxWeight = featureImportanceScores.reduce((sumSoFar, val) => sumSoFar + val, 0);
  const overallUtility = maxWeight === 0 ? 1 : (weightedFeatureUtilitiesSum / maxWeight);
  return overallUtility;
}

export const getItemGroups = (allCities, sliderStates, chosenAttributeOrder, hiddenAttrKeys) => {
    return flow(
      map(c => { return { city: c, name: c.name, country: c.country, invertedUtility: 1.0-overallUtilityBySliderStates(c, sliderStates, chosenAttributeOrder.filter(otherAttrKey => !hiddenAttrKeys.includes(otherAttrKey))) }; }),
      sortBy(['invertedUtility','name']),
      multiPartition([utilityAtLeast(1.0), utilityAtLeast(0.8)])
    )(allCities);
  }



  // --------------- lodash-fp.flow functions ------------------

  export const multiPartition = curry((predicateFunctions, array) => {
    const partitions = predicateFunctions.map((predicate) =>
      partition(predicate, array)
    );
  
    const result = [];
    const seenElements = [];
    partitions.forEach(([fulfilling, _nonFulfilling], index) => {
      const fulfillingOnlyNow = fulfilling.filter(element => !seenElements.includes(element));
      result.push(fulfillingOnlyNow);
      seenElements.push(...fulfillingOnlyNow);
    });
    result.push(array.filter(element => !seenElements.includes(element)));
  
    return result;
  });

  export const utilityAtLeast = threshold => ({city, invertedUtility}) => {
    const utility = 1.0-invertedUtility;
    return utility >= threshold;
  }




